import React, { useMemo, FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ProfilesTableRow from "./ProfilesTableRow";
import { Table } from "@bbdevcrew/bb_ui_kit_fe";
import { ProfilesTablePrefix } from "./ProfilesTablePrefix";

import s from "./ProfilesTable.module.less";

import { IProfilesSort, IProfilesTableProps, ProfilesColumnSortType } from "./ProfilesTable.types";
import {
  getProfilesTableColumns,
  getSortedData,
  initialSort,
  PAGE_SIZE,
} from "./ProfilesTable.helpers";
import { IDashboardProfilesRow } from "@store/dashboard/types";
import { ITableColumn, SortType } from "@bbdevcrew/bb_ui_kit_fe";

export const ProfilesTable: FC<IProfilesTableProps> = ({ data, loading, total }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => getProfilesTableColumns(), []);

  const [sort, setSort] = useState<IProfilesSort>(initialSort);
  const [page, setPage] = useState<number>(1);
  const [tableData, setTableData] = useState<IProfilesTableProps["data"]>([]);

  useEffect(() => {
    setTableData(getSortedData(data, page, sort));
  }, [data, sort, page]);

  const onSort = (column: ITableColumn, _sort?: SortType) => {
    if (!_sort) {
      setSort(initialSort);
    } else {
      setSort({
        order: _sort,
        field: column.id_name as ProfilesColumnSortType,
      });
    }
  };

  return (
    <div className={s.bbProfilesWrapper} data-cy="profiles-table">
      <Table<IDashboardProfilesRow>
        data={tableData}
        onSort={onSort}
        tableRow={ProfilesTableRow}
        columns={columns}
        loading={loading}
        pagination={{
          total: data.length,
          pageSize: PAGE_SIZE,
          currentPage: page,
          onChangePage: setPage,
        }}
        rowsPrefix={<ProfilesTablePrefix value={total} />}
        withStickyHeader
        emptyTableText={t("generic:emptyTable")}
        pageOutOfText={[
          t("components:sortableTable:pageOutOfPart1"),
          t("components:sortableTable:pageOutOfPart2"),
        ]}
        tableClassName={s.bbProfilesTable}
        containerClassName={s.bbProfilesTableContainer}
        stonlySelector="intelligence-profiles_table"
      />
    </div>
  );
};
