export const GET_DASHBOARD = "DASHBOARD/GET_DASHBOARD";
export const GET_DASHBOARD_SUCCESS = "DASHBOARD/GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "DASHBOARD/GET_DASHBOARD_FAILURE";

export const GET_DASHBOARD_CSV = "DASHBOARD/GET_CSV";
export const GET_DASHBOARD_CSV_FAILURE = "DASHBOARD/GET_CSV_FAILURE";

export const GET_DASHBOARD_PROFILES = "DASHBOARD/GET_DASHBOARD_PROFILES";
export const GET_DASHBOARD_PROFILES_SUCCESS = "DASHBOARD/GET_DASHBOARD_PROFILES_SUCCESS";
export const GET_DASHBOARD_PROFILES_FAILURE = "DASHBOARD/GET_DASHBOARD_PROFILES_FAILURE";
