import React from "react";
import classNames from "classnames";

import { NavBadge, SimpleSelectTrigger } from "@bbdevcrew/bb_ui_kit_fe";
import ColorDot from "../ColorDot";

import { cropText } from "@utils/cropText";

import s from "./PresetSelector.module.less";

import { IPresetSelectorProps } from "./PresetSelector.types";

export const PresetSelector: React.FC<IPresetSelectorProps> = ({
  open,
  isCollapsed,
  value,
  allLabel,
  className,
  ...restProps
}) => {
  const count = value?.counts?.find(c => c.id === "total")?.count;

  return (
    <SimpleSelectTrigger
      active={open}
      className={classNames(
        s.bbPresetSelector,
        {
          [s.bbPresetSelectorCollapsed]: isCollapsed,
        },
        className,
      )}
      {...restProps}
    >
      <div className={s.bbPresetSelectorValue}>
        {value ? (
          <>
            <div className={s.bbPresetSelectorValueInner}>
              {value?.color ? <ColorDot color={value.color} /> : null}
              {!isCollapsed && cropText(value?.name || "", 15)}
            </div>
            {count !== undefined && (
              <NavBadge className={s.bbPresetSelectorValueCount}>{count}</NavBadge>
            )}
          </>
        ) : (
          <div className={s.bbPresetSelectorValueInner}>
            <ColorDot color="all" />
            {!isCollapsed && allLabel}
          </div>
        )}
      </div>
    </SimpleSelectTrigger>
  );
};
