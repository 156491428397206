import React from "react";
import { i18nextInstance } from "../../../../App";

import { Trans } from "react-i18next";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ProfilesTable.module.less";

import { ISimpleTableColumn } from "@bbdevcrew/bb_ui_kit_fe";
import { IProfilesSort, IProfilesTableProps } from "./ProfilesTable.types";

import { InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";
import {
  FacebookRoundColorIcon,
  InstagramRoundColor2Icon,
  LinkedinRoundColorIcon,
  TiktokRoundColor2Icon,
  TwitterRoundColorIcon,
  UserIcon,
  YoutubeRoundColorIcon,
} from "@assets/index";

const TOOLTIP_WIDTH = 300;
export const PAGE_SIZE = 50;

const getColumnPrefix = (content: React.ReactNode) => (
  <span className={s.bbColumnPrefix}>{content}</span>
);

export const initialSort: IProfilesSort = {
  order: "asc",
  field: "platform",
};

export const PlatformTypeIconRound = {
  facebook: FacebookRoundColorIcon,
  instagram: InstagramRoundColor2Icon,
  twitter: TwitterRoundColorIcon,
  tiktok: TiktokRoundColor2Icon,
  linkedin: LinkedinRoundColorIcon,
  youtube: YoutubeRoundColorIcon,
};

export const getPlatformIcon = (platformTypeId: string) => {
  const Icon = PlatformTypeIconRound[platformTypeId as keyof typeof PlatformTypeIconRound];
  return Icon ? <Icon /> : null;
};

export const getProfilesTableColumns = (): ISimpleTableColumn[] => {
  return [
    {
      name: i18nextInstance.t("components:listen:profiles:table:columns:platform"),
      id_name: "platform",
      colSpan: 5,
    },
    {
      name: i18nextInstance.t("components:listen:profiles:table:columns:followers"),
      id_name: "followers_count",
      colSpan: 5,
      prefix: getColumnPrefix(<UserIcon />),
      suffix: (
        <Tooltip
          placement="top"
          showArrow={false}
          overlayInnerStyle={{
            minWidth: TOOLTIP_WIDTH,
          }}
          title={
            <Trans
              i18nKey={"components:listen:profiles:table:tooltip"}
              components={{
                br: <br />,
                strong: <strong />,
              }}
            />
          }
        >
          <div className={s.bbHeaderColIcon}>
            <InformationIcon />
          </div>
        </Tooltip>
      ),
    },
    {
      name: i18nextInstance.t("components:listen:profiles:table:columns:percentage"),
      id_name: "percentage",
      colSpan: 7,
      prefix: getColumnPrefix(<UserIcon />),
    },
    {
      name: i18nextInstance.t("components:listen:profiles:table:columns:growth"),
      id_name: "growth",
      colSpan: 7,
      prefix: getColumnPrefix(<UserIcon />),
    },
  ];
};

export const getSortedData = (
  data: IProfilesTableProps["data"],
  page: number,
  sort?: IProfilesSort,
) => {
  if (!sort) {
    return data.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  }

  return [...data]
    .sort((a, b) => {
      const valueA = a[sort.field];
      const valueB = b[sort.field];

      if (valueA && !valueB) return sort.order === "asc" ? 1 : -1;
      if (!valueA && valueB) return sort.order === "asc" ? -1 : 1;

      if (valueA && valueB) {
        if (sort.order === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      }

      return 0;
    })
    .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
};
