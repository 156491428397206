import * as actions from "./actions";
import { ActionType } from "typesafe-actions";

export type IntegrationsActions = ActionType<typeof actions>;

export interface IIntegrationsListResponse {
  items: Integration[];
}

export interface Integration {
  id: string;
  subdomain: string;
  email: string;
  token: string;
}

export interface IIntegrationsState {
  integrations: Integration[];

  fetchingIntegrationsList: boolean;
  fetchedIntegrationsList: boolean;
  fetchedIntegrationsListFail: boolean;

  creatingIntegration: boolean;
  createdIntegration: boolean;
  creatingIntegrationFailed: boolean;
  creatingIntegrationFailedMessage: string;

  updatingIntegration: boolean;
  updatedIntegration: boolean;
  updatingIntegrationFailed: boolean;
  updatingIntegrationFailedMessage: string;

  deletingIntegration: boolean;
  deletedIntegration: boolean;
  deletingIntegrationFailed: boolean;
  deletingIntegrationFailedMessage: string;

  creatingTicket: boolean;
  createdTicket: boolean;
  creatingTicketFailed: boolean;
  creatingTicketFailedMessage: string;

  updatingTicket: boolean;
  updatedTicket: boolean;
  updatingTicketFailed: boolean;
  updatingTicketFailedMessage: string;

  sendingNewInternalComment: boolean;
  sentNewInternalComment: boolean;
  sendingNewInternalCommentFailed: boolean;
  sendingNewInternalCommentFailedMessage: string;
}

export interface ICreateIntegrationPayload {
  subdomain: string;
  email: string;
  token: string;
}

export type IDeleteIntegrationPayload = string;

export interface IZendeskTicketInternalReply {
  message: string;
  author_name?: string;
}

export enum ZendeskTicketStatus {
  New = "new",
  Open = "open",
  Pending = "pending",
  Hold = "hold",
  Solved = "solved",
  Closed = "closed",
}

export enum ZendeskTicketPriority {
  Urgent = "urgent",
  High = "high",
  Normal = "normal",
  Low = "low",
}

export interface IZendeskTicket {
  id: string;
  subject: string;
  priority?: ZendeskTicketPriority;
  status?: ZendeskTicketStatus;
  type?: string;
  description: string;
  internal_replies: IZendeskTicketInternalReply[];
}

export interface ICreateTicketPayload extends Omit<IZendeskTicket, "id" | "internal_replies"> {
  type?: string;
  tags: string[];
  requester?: {
    name: string;
    email: string;
  };
  integration_id?: string;
  comment_id?: string;
}

export interface ISendNewInternalCommentPayload {
  ticketId: string;
  message: string;
}
