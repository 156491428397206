import { RootState } from "..";

export const fromIntegrations = (state: RootState) => state.integrations;

// Integrations
export const getIntegrationsListSelector = (state: RootState) =>
  fromIntegrations(state).integrations;
export const getIntegrationsListPendingSelector = (state: RootState) =>
  fromIntegrations(state).fetchingIntegrationsList;
export const getIntegrationsListSuccessfulSelector = (state: RootState) =>
  fromIntegrations(state).fetchedIntegrationsList;
export const getIntegrationsListFailureSelector = (state: RootState) =>
  fromIntegrations(state).fetchedIntegrationsListFail;

export const creatingIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).creatingIntegration;
export const createdIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).createdIntegration;
export const creatingIntegrationFailedSelector = (state: RootState) =>
  fromIntegrations(state).creatingIntegrationFailed;
export const creatingIntegrationFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).creatingIntegrationFailedMessage;

export const updatingIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).updatingIntegration;
export const updatedIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).updatedIntegration;
export const updatingIntegrationFailedSelector = (state: RootState) =>
  fromIntegrations(state).updatingIntegrationFailed;
export const updatingIntegrationFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).updatingIntegrationFailedMessage;

export const deletingIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).deletingIntegration;
export const deletedIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).deletedIntegration;
export const deletingIntegrationFailedSelector = (state: RootState) =>
  fromIntegrations(state).deletingIntegrationFailed;
export const deletingIntegrationFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).deletingIntegrationFailedMessage;

// Tickets
export const creatingTicketSelector = (state: RootState) => fromIntegrations(state).creatingTicket;
export const createdTicketSelector = (state: RootState) => fromIntegrations(state).createdTicket;
export const creatingTicketFailedSelector = (state: RootState) =>
  fromIntegrations(state).creatingTicketFailed;
export const creatingTicketFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).creatingTicketFailedMessage;

export const updatingTicketSelector = (state: RootState) => fromIntegrations(state).updatingTicket;
export const updatedTicketSelector = (state: RootState) => fromIntegrations(state).updatedTicket;
export const updatingTicketFailedSelector = (state: RootState) =>
  fromIntegrations(state).updatingTicketFailed;
export const updatingTicketFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).updatingTicketFailedMessage;

export const sendingNewInternalCommentSelector = (state: RootState) =>
  fromIntegrations(state).sendingNewInternalComment;
export const sentNewInternalCommentSelector = (state: RootState) =>
  fromIntegrations(state).sentNewInternalComment;
export const sendingNewInternalCommentFailedSelector = (state: RootState) =>
  fromIntegrations(state).sendingNewInternalCommentFailed;
export const sendingNewInternalCommentFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).sendingNewInternalCommentFailedMessage;
