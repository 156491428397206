import React from "react";
import classNames from "classnames/bind";

import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";
import { Col } from "antd";

import s from "./ProfilesTable.module.less";

import { IDashboardProfilesRow } from "@store/dashboard/types";
import { formatter } from "@components/_common/CardStat/helpers";

export const ProfilesTablePrefix = ({ value }: { value?: IDashboardProfilesRow }) => {
  return (
    <div className={classNames(s.bbProfilesTableRow, s.bbProfilesTablePrefix)}>
      <Col span={5}>{value?.asset_name}</Col>
      <Col span={5}>{value?.followers_count != null ? formatter(value?.followers_count) : ""}</Col>
      <Col span={7}>{value?.percentage != null ? `${value.percentage}%` : ""}</Col>
      <Col span={7}>
        {value?.growth && <PerformanceCardPill percentageValue={Number(value.growth)} />}
      </Col>
    </div>
  );
};
