import { getBreakdownChartBarColor } from "@components/_common/BreakdownChart/helpers";
import { IDashboardProfilesChart } from "@store/dashboard/types";

export const normalizePlatformData = (platformData: IDashboardProfilesChart[]) => {
  return platformData
    .filter(i => !!i.name)
    .map((item, index) => {
      return {
        value: item.followers_count,
        rawName: item.id,
        name: item.name,
        color: getBreakdownChartBarColor(item.id, index),
        change_percentage: Number(item.growth),
        percentage_of_total: Number(item.percentage),
      };
    })
    .sort((a, b) => (b.rawName && a.rawName ? (b.rawName > a.rawName ? -1 : 1) : 0));
};
