import { t } from "i18next";

const messageStatuses = [
  {
    id: "archived",
    label: t("components:filters:message_statuses:options:archived"),
  },
  {
    id: "starred",
    label: t("components:filters:message_statuses:options:starred"),
  },
  {
    id: "replied",
    label: t("components:filters:message_statuses:options:replied"),
    items: [
      {
        id: "replied_by_bb",
        label: t("components:filters:message_statuses:options:replied_by_bb"),
      },
      {
        id: "replied_in_platform",
        label: t("components:filters:message_statuses:options:replied_in_platform"),
      },
    ],
  },
];

export { messageStatuses };
