import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";
import SentimentPreview from "./SentimentPreview";
import ActionSummary from "./ActionSummary";
import BBProtect from "./BBProtect";
import BBEngage from "./BBEngage";
import SentimentDetails from "./SentimentDetails";
import Topics from "./Topics";

import { meSelector } from "@store/me/selectors";
import {
  getShortenedSearchesAction,
  postShortenedSearchesAction,
} from "@store/shortenedSearches/actions";
import {
  getShortenedSearchesResponseSelector,
  getShortenedSearchesSuccessfulSelector,
  postShortenedSearchesSuccessfulSelector,
  shortenedSearchesIdSelector,
} from "@store/shortenedSearches/selectors";
import { generatePDFDocumentAction } from "@store/reports/actions";
import { pdfReportSelector } from "@store/reports/selectors";
import { savedFiltersSelector } from "@store/savedFilters/selectors";
import { getPredefinedFiltersAction } from "@store/savedFilters/actions";

import { useDashboardData } from "@utils/useDashboardData";
import { dashboards, getDateRange, REPORT_READY_SELECTOR, usePDFParams } from "./PDFReport.helpers";
import { forceFileDownload } from "../ShareReportButton/ShareReportButton.helpers";

import s from "./PDFReport.module.less";

import { OverviewComponentType } from "@store/dashboard/types";

import { LoadingOutlined } from "@ant-design/icons";
import MessageTypes from "./MessageTypes";
import { IPDFReportProps } from "./PDFReport.types";
import { IFilters } from "@store/filters/types";

const PDFReportSections: React.FC<{ filters: IFilters }> = ({ filters }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { name, mode, sections } = usePDFParams();

  const id = useSelector(shortenedSearchesIdSelector);
  const { inProgress, filename, url } = useSelector(pdfReportSelector(id ?? ""));

  const [trendingCommentsLoaded, setTrendingCommentsLoaded] = useState(
    !sections.includes("sentiment_preview"),
  );

  const components = useMemo<OverviewComponentType[]>(() => {
    return sections.reduce<OverviewComponentType[]>((result, acc) => {
      return [...result, ...dashboards[acc]];
    }, []);
  }, [sections]);

  const { data, isFetching } = useDashboardData(components, filters);

  const startDownload = useMemo(() => mode === "download", [mode]);
  const downloadReady = startDownload && !inProgress && filename && url;
  const contentLoaded = !isFetching && trendingCommentsLoaded && data;

  useEffect(() => {
    if (downloadReady) {
      forceFileDownload(url, filename);
    }
  }, [downloadReady, url, filename]);

  useEffect(() => {
    document.body.classList.add(s.bbPDFReportBody);
    if (contentLoaded) {
      setTimeout(() => {
        document.body.classList.add(REPORT_READY_SELECTOR);
      }, 1000);
    }
  }, [contentLoaded]);

  useEffect(() => {
    if (startDownload && id) {
      dispatch(
        generatePDFDocumentAction({
          search_id: id,
          name,
          sections,
          ui_route_path: window.location.pathname,
        }),
      );
    }
  }, [startDownload, mode, id, name, sections, dispatch]);

  const isOverview = sections.includes("message_types");
  const hasOverviewOrSentiment = sections.includes("sentiment_extended") || isOverview;

  const componentMap: Record<string, React.FC> = useMemo(
    () => ({
      sentiment_preview() {
        return (
          <SentimentPreview
            data={{
              sentiment: data?.explorer_overview_sentiment?.items || [],
              platforms: data?.explorer_overview_platform?.items || [],
            }}
            isFetching={isFetching}
            filters={filters}
            title={
              isOverview
                ? t("pages:insights:tabs:overview.title")
                : t(`components:pdfReport:sections:sentiment_preview`)
            }
            onLoad={() => setTrendingCommentsLoaded(true)}
          />
        );
      },
      action_summary() {
        return <ActionSummary data={data?.action_summary_stats || []} isFetching={isFetching} />;
      },
      message_types() {
        return (
          <MessageTypes
            messageTypes={data?.explorer_overview_message_types}
            languages={data?.explorer_overview_top_languages?.items}
            influencers={data?.explorer_overview_top_influencers?.items}
            isFetching={isFetching}
            filters={filters}
          />
        );
      },
      bb_protect() {
        return <BBProtect data={data?.hidden_comments} isFetching={isFetching} />;
      },
      bb_engage() {
        return <BBEngage data={data?.explorer_overview_care} isFetching={isFetching} />;
      },
      sentiment_extended() {
        return (
          <SentimentDetails
            data={data?.sentiment_extended}
            isFetching={isFetching}
            title={t(`components:pdfReport:sections:sentiment_details`)}
            filters={filters}
            className={classNames({
              [s.bbPDFReportNewPage]: isOverview,
            })}
          />
        );
      },
      topics() {
        return (
          <Topics
            data={{
              workCloudData: data?.explorer_overview_top_keywords?.items || [],
              emojis: data?.explorer_overview_top_emojis?.items || [],
              keywordPhrasesData: data?.explorer_overview_top_keyword_phrases?.items || [],
              tags: data?.top_tags?.items || [],
            }}
            isFetching={isFetching}
            title={t("pages:overviewSections:topics:title")}
            filters={filters}
            className={classNames({
              [s.bbPDFReportNewPage]: hasOverviewOrSentiment,
            })}
          />
        );
      },
    }),
    [data, isFetching, filters, t, isOverview, hasOverviewOrSentiment],
  );

  return (
    <>
      {sections
        .filter(section => componentMap[section])
        .map(section => {
          const Component = componentMap[section];
          return <Component key={section} />;
        })}
    </>
  );
};

export const PDFReport: React.FC<IPDFReportProps> = ({ type = "managed-report" }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { name = "", id: searchId, savedFilterId, mode } = usePDFParams();

  const filters = useSelector(getShortenedSearchesResponseSelector);
  const me = useSelector(meSelector);
  const fetchedGetShortenedSearches = useSelector(getShortenedSearchesSuccessfulSelector);
  const fetchedPostShortenedSearches = useSelector(postShortenedSearchesSuccessfulSelector);
  const id = useSelector(shortenedSearchesIdSelector);
  const { inProgress } = useSelector(pdfReportSelector(id));
  const savedFilters = useSelector(savedFiltersSelector);

  const fetchedShortenedSearches = fetchedGetShortenedSearches || fetchedPostShortenedSearches;

  const dateRange = getDateRange(filters);
  const startDownload = useMemo(() => mode === "download", [mode]);
  const savedFilter = useMemo(
    () => savedFilters?.items.find(filter => String(filter.id) === String(savedFilterId)),
    [savedFilterId, savedFilters],
  );

  useEffect(() => {
    if (searchId && !savedFilterId) {
      dispatch(getShortenedSearchesAction(searchId));
    }
  }, [searchId, savedFilterId, dispatch]);

  useEffect(() => {
    if (savedFilterId) {
      dispatch(getPredefinedFiltersAction());
    }
  }, [dispatch, savedFilterId]);

  useEffect(() => {
    if (savedFilter) {
      dispatch(postShortenedSearchesAction({ filters: savedFilter.request }));
    }
  }, [dispatch, savedFilter]);

  const logo = me?.client?.logo;
  const title = t(`components:pdfReport:${type === "managed-report" ? "managedTitle" : "title"}`, {
    name,
  });

  const currentFilters = savedFilterId ? savedFilter?.request : filters;

  return (
    <div className={s.bbPDFReport}>
      {startDownload && inProgress && (
        <div className={s.bbPDFReportLoading}>
          <Loading isLoading type="spinning" indicator={<LoadingOutlined />} />
          <h2 className={s.bbPDFReportLoadingTitle}>
            {t("components:pdfReport:loadingScreen:title")}
          </h2>
          {t("components:pdfReport:loadingScreen:description")}
        </div>
      )}
      <div className={s.bbPDFReportHeader}>
        <h2 className={s.bbPDFReportHeaderTitle}>{title}</h2>
        <div className={s.bbPDFReportHeaderDateRange}>{dateRange}</div>
        {logo && (
          <div className={s.bbPDFReportHeaderLogo}>
            <img src={logo} />
          </div>
        )}
      </div>
      {fetchedShortenedSearches && !!currentFilters && (
        <PDFReportSections filters={currentFilters} />
      )}
    </div>
  );
};
