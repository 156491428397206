import { IBar } from "@bbdevcrew/bb_ui_kit_fe";
import { IPostTag } from "@store/postTags/types";
import { IPlatform, IPlatformType, PlatformType } from "@store/platform/types";
import { IYouTubeOptions } from "@components/publish/postCreation/YouTubeOptions";
import { IPublishingPostLocation } from "@store/publishings/types";

export interface IPostImageProps {
  post: IPost;
}

export interface IPostChartProps {
  stats?: any; // eslint-disable-line
  bars?: any; // eslint-disable-line
  total?: IPostTotal;
}

export interface IPostInfoProps {
  post: IPost;
  texts?: IPostTexts;
  tooltipDelay?: number;
  tooltips?: IPostTooltips;
}

export interface IPostModalProps {
  isOpen: boolean;
  onClose: () => void;
  post?: IPost;
  isLoading?: boolean;
  bars?: IBar[];
  showPostTags?: boolean;
  stats?: { [key: string]: string | number | undefined }[];
}

export interface IPostReactionsProps {
  post?: IPost;
  platform?: PlatformType;
}

export interface IPostTotal {
  color: string;
  name: string;
}

export interface IPostTexts {
  postId: string;
  assetId: string;
  postType: string;
  linkToPost: string;
  spinText: string;
}

export interface IPostTooltips {
  postId: string;
  assetId: string;
  postType: string;
}

export interface IPostProps {
  post?: IPost;
  stats?: any; // eslint-disable-line
  bars?: any; // eslint-disable-line
  isLoading?: boolean;
  total?: IPostTotal;
  tooltipDelay?: number;
  platform?: PlatformType;
  texts?: IPostTexts;
  tooltips?: IPostTooltips;
  showPostTags: boolean;
  maxTagListLength?: number;
}

export interface IGenericItem {
  id: string;
  label: string;
}

export interface ICommentMetrics {
  total_comments: number;
  positive_comments: number;
  negative_comments: number;
  neutral_comments: number;
  user_tags_comments: number;
  not_applicable_comments: number;
  hidden_comments?: number;
  brand_comments?: number;
  nss_score?: number;
  positive_comments_percent?: number;
  negative_comments_percent?: number;
  neutral_comments_percent?: number;
  user_tags_comments_percent?: number;
  hidden_comments_percent?: number;
  brand_comments_percent?: number;
}

export interface IPostMetrics {
  total_reaches: number | string;
  total_views: number | string;
  engagement_rate: number;
  total_reactions: number;
  total_shares: number;
  total_spend?: number;
}

export type PostMentionType =
  | "MENTION"
  | "CAPTION_MENTION"
  | "REVIEW"
  | "HASHTAG_MENTION"
  | "COMMENT_MENTION"
  | "QUOTE_MENTION";
export type RatingStarsType = "ONE_STAR" | "TWO_STAR" | "THREE_STAR" | "FOUR_STAR" | "FIVE_STAR";
export type RecommendationType = "POSITIVE" | "NEGATIVE" | RatingStarsType;
export type PostMediaType = "image" | "video" | "multiimage" | "pdf" | "doc" | "ppt" | "other";
export type PublishingType = "POST" | "STORY";
export enum PostPlacementEnum {
  STORY = "STORY",
  DM = "DM",
  FEED = "FEED",
  AD = "AD",
  REVIEW = "REVIEW",
}
export enum DistributionDataOrderEnum {
  POSITIVE,
  FIVE_STAR,
  FOUR_STAR,
  THREE_STAR,
  TWO_STAR,
  ONE_STAR,
  NEGATIVE,
}

export interface IPostMedia {
  id: PostMediaType;
  label: string;
}

export interface IPostReactions {
  like?: number;
  love?: number;
  wow?: number;
  haha?: number;
  sad?: number;
  angry?: number;
  thankful?: number;
  pride?: number;
  care?: number;
  view?: number;
  dislike?: number;
}

export interface IPost {
  id: string;
  author_name: string;
  object_id: string;
  message: string;
  title?: string;
  page_id: string;
  page_name: string;
  permalink: string;
  owned: boolean;
  picture?: string;
  attachments: IPostAttachment[];
  created_time: number;
  created_time_pretty: string;
  reactions?: IPostReactions;
  has_ads?: boolean;
  post_type: PostMentionType;
  type: PostMentionType;
  platform: IPlatform;
  platform_type: IPlatformType;
  insights?: IInsight[];
  post_category_type?: IGenericItem;
  post_media_type?: IPostMedia;
  targetings?: IGenericItem[];
  comment_metrics: ICommentMetrics;
  post_metrics: IPostMetrics;
  post_placement?: PostPlacementEnum;
  publishing_type?: PublishingType;
  post_tags: IPostTag[];
  location?: IPublishingPostLocation;
  publishing_youtube_options?: IYouTubeOptions;
}

interface IInsight {
  label: string;
  value: number;
}

export interface IPostAttachmentMediaImage {
  width: number;
  height: number;
  src: string;
}

export interface IPostAttachmentMedia {
  image: IPostAttachmentMediaImage;
  source: string;
}

export interface IMediaTag {
  username: string;
  x: number;
  y: number;
}

export interface IPostAttachment {
  id: string;
  type: string;
  url: string;
  title?: string;
  description: string;
  media: IPostAttachmentMedia;
  media_tags?: IMediaTag[];
  alt_text?: string;
}

export interface IPostType {
  id: string;
  label: string;
  description?: string;
}
