import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import SavedReplyCard from "../common/itemCard/ItemCard";

import { IReplyList, SavedReplyTabKeys } from "./helper";

import { deleteSavedRepliesAction } from "@store/replies/actions";
import { getSavedRepliesSelector } from "@store/replies/selectors";

import s from "./SavedReplyList.module.less";

export default function SavedReplyList({ type, onViewChange, onReplyClick }: IReplyList) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const savedReplies = useSelector(getSavedRepliesSelector);
  const replies = useMemo(() => {
    return type === SavedReplyTabKeys.CustomReplies
      ? savedReplies?.items
      : type === SavedReplyTabKeys.BBReplies
        ? savedReplies?.internal_items
        : [];
  }, [savedReplies, type]);

  const deleteSavedReplies = useCallback(
    id => {
      dispatch(
        deleteSavedRepliesAction(
          id,
          type === SavedReplyTabKeys.CustomReplies ? "custom" : "internal",
        ),
      );
    },
    [dispatch, type],
  );

  const onClickDeleteSavedReplies = (id: string) => {
    deleteSavedReplies(id);
  };

  return (
    <div className={s.bbSavedReplyList}>
      {replies?.map(reply => {
        const { id, name, message, tags, custom_tags } = reply;
        const tagLabels: { id: string; label: string }[] = [
          ...(tags || []),
          ...(custom_tags || []),
        ].map(tag => ({
          id: tag.id || "",
          label: tag.label,
        }));

        return (
          <SavedReplyCard
            name={name}
            message={message}
            key={id}
            onItemClick={() => {
              onReplyClick?.(reply);
            }}
            tags={tagLabels}
            actions={[
              {
                key: "edit",
                label: t("generic:edit"),
                onActionClick: () => onViewChange("edit", type, id),
              },
              {
                key: "delete",
                label: t("generic:delete"),
                onActionClick: () => onClickDeleteSavedReplies(id),
                confirmationText: t("components:reply:savedReplies:list:deletePrompt"),
              },
            ]}
          />
        );
      })}
    </div>
  );
}
