export const GET_REPLIES = "REPLIES/GET_REPLIES";
export const GET_REPLIES_SUCCESS = "REPLIES/GET_REPLIES_SUCCESS";
export const GET_REPLIES_FAILURE = "REPLIES/GET_REPLIES_FAILURE";

export const GET_REPLIES_ATTACHMENTS = "REPLIES/GET_REPLIES_ATTACHMENTS";
export const GET_REPLIES_ATTACHMENTS_SUCCESS = "REPLIES/GET_REPLIES_ATTACHMENTS_SUCCESS";
export const GET_REPLIES_ATTACHMENTS_FAILURE = "REPLIES/GET_REPLIES_ATTACHMENTS_FAILURE";

export const GET_THREAD_REPLIES = "REPLIES/GET_THREAD_REPLIES";
export const GET_THREAD_REPLIES_SUCCESS = "REPLIES/GET_THREAD_REPLIES_SUCCESS";
export const GET_THREAD_REPLIES_FAILURE = "REPLIES/GET_THREAD_REPLIES_FAILURE";

export const PATCH_REPLIES_PARENT_SENTIMENT = "REPLIES/PATCH_REPLIES_PARENT_SENTIMENT";
export const PATCH_REPLIES_PARENT_SENTIMENT_SUCCESS =
  "REPLIES/PATCH_REPLIES_PARENT_SENTIMENT_SUCCESS";
export const PATCH_REPLIES_PARENT_SENTIMENT_FAILURE =
  "REPLIES/PATCH_REPLIES_PARENT_SENTIMENT_FAILURE";

export const PATCH_REPLIES_ITEM_SENTIMENT = "REPLIES/PATCH_REPLIES_ITEM_SENTIMENT";
export const PATCH_REPLIES_ITEM_SENTIMENT_SUCCESS = "REPLIES/PATCH_REPLIES_ITEM_SENTIMENT_SUCCESS";
export const PATCH_REPLIES_ITEM_SENTIMENT_FAILURE = "REPLIES/PATCH_REPLIES_ITEM_SENTIMENT_FAILURE";

export const POST_SAVED_REPLIES = "REPLIES/POST_SAVED_REPLIES";
export const POST_SAVED_REPLIES_SUCCESS = "REPLIES/POST_SAVED_REPLIES_SUCCESS";
export const POST_SAVED_REPLIES_FAILURE = "REPLIES/POST_SAVED_REPLIES_FAILURE";

export const DELETE_SAVED_REPLIES = "REPLIES/DELETE_SAVED_REPLIES";
export const DELETE_SAVED_REPLIES_SUCCESS = "REPLIES/DELETE_SAVED_REPLIES_SUCCESS";
export const DELETE_SAVED_REPLIES_FAILURE = "REPLIES/DELETE_SAVED_REPLIES_FAILURE";

export const DELETE_BRAND_REPLY = "REPLIES/DELETE_BRAND_REPLY";
export const DELETE_BRAND_REPLY_SUCCESS = "REPLIES/DELETE_BRAND_REPLY_SUCCESS";
export const DELETE_BRAND_REPLY_FAILURE = "REPLIES/DELETE_BRAND_REPLY_FAILURE";

export const GET_REPLIES_PAGE = "REPLIES/GET_REPLIES_PAGE";
export const GET_REPLIES_PAGE_SUCCESS = "REPLIES/GET_REPLIES_PAGE_SUCCESS";

export const POST_COMMENT_REPLY = "REPLIES/POST_COMMENT_REPLY";
export const POST_COMMENT_REPLY_SUCCESS = "REPLIES/POST_COMMENT_REPLY_SUCCESS";
export const POST_COMMENT_REPLY_FAILURE = "REPLIES/POST_COMMENT_REPLY_FAILURE";
export const POST_COMMENT_REPLY_REGION_IS_NOT_ALLOWED_FAILURE =
  "REPLIES/POST_COMMENT_REPLY_REGION_IS_NOT_ALLOWED_FAILURE";

export const POST_SAVED_REPLY = "REPLIES/POST_SAVED_REPLY";
export const POST_SAVED_REPLY_SUCCESS = "REPLIES/POST_SAVED_REPLY_SUCCESS";
export const POST_SAVED_REPLY_FAILURE = "REPLIES/POST_SAVED_REPLY_FAILURE";

export const PUT_SAVED_REPLY = "REPLIES/PUT_SAVED_REPLY";
export const PUT_SAVED_REPLY_SUCCESS = "REPLIES/PUT_SAVED_REPLY_SUCCESS";
export const PUT_SAVED_REPLY_FAILURE = "REPLIES/PUT_SAVED_REPLY_FAILURE";

export const GET_REPLIES_AI_SUGGESTION = "REPLIES/GET_REPLIES_AI_SUGGESTION";
export const GET_REPLIES_AI_SUGGESTION_SUCCESS = "REPLIES/GET_REPLIES_AI_SUGGESTION_SUCCESS";
export const GET_REPLIES_AI_SUGGESTION_FAILURE = "REPLIES/GET_REPLIES_AI_SUGGESTION_FAILURE";

export const PERFORM_REPLIES_ITEMS_ACTION_SUCCESS = "REPLIES/PERFORM_REPLIES_ITEMS_ACTION_SUCCESS";
export const PERFORM_REPLIES_ITEMS_ACTION_SUCCESS_SUCCESS =
  "REPLIES/PERFORM_REPLIES_ITEMS_ACTION_SUCCESS_SUCCESS";
export const PERFORM_REPLIES_ITEMS_ACTION_SUCCESS_FAILURE =
  "REPLIES/PERFORM_REPLIES_ITEMS_ACTION_SUCCESS_FAILURE";

export const PERFORM_REPLIES_PARENT_ACTION_SUCCESS =
  "REPLIES/PERFORM_REPLIES_PARENT_ACTION_SUCCESS";
export const PERFORM_REPLIES_PARENT_ACTION_SUCCESS_SUCCESS =
  "REPLIES/PERFORM_REPLIES_PARENT_ACTION_SUCCESS_SUCCESS";
export const PERFORM_REPLIES_PARENT_ACTION_SUCCESS_FAILURE =
  "REPLIES/PERFORM_REPLIES_PARENT_ACTION_SUCCESS_FAILURE";

export const POST_REPLY_BB_TAG = "REPLIES/POST_REPLY_BB_TAG";
export const DELETE_REPLY_BB_TAG = "REPLIES/DELETE_REPLY_BB_TAG";

export const SET_SAVED_REPLY_BB_TAGS = "REPLIES/SET_SAVED_REPLY_BB_TAGS";
export const SET_SAVED_REPLY_CUSTOM_TAGS = "REPLIES/SET_SAVED_REPLY_CUSTOM_TAGS";

export const CLEAR_REPLIES = "REPLIES/CLEAR_REPLIES";
export const CLEAR_SAVED_REPLY_ALL_TAGS = "REPLIES/CLEAR_SAVED_REPLY_ALL_TAGS";
export const CLEAR_REPLIES_COMMENT_LIST = "REPLIES/CLEAR_REPLIES_COMMENT_LIST";
export const CLEAR_REPLYING_THREAD_COMMENT = "REPLIES/CLEAR_REPLYING_THREAD_COMMENT";

export const SET_DRAFT_REPLY = "REPLIES/SET_DRAFT_REPLY";

export const UPLOAD_REPLY_IMAGE = "REPLIES/UPLOAD_REPLY_IMAGE";
export const UPLOAD_REPLY_IMAGE_SUCCESS = "REPLIES/UPLOAD_REPLY_IMAGE_SUCCESS";
export const UPLOAD_REPLY_IMAGE_FAILURE = "REPLIES/UPLOAD_REPLY_IMAGE_FAILURE";
export const CLEAR_REPLY_IMAGE = "REPLIES/CLEAR_REPLY_IMAGE";

export const GET_ACCOUNT_POSTS = "REPLIES/GET_ACCOUNT_POSTS";
export const GET_ACCOUNT_POSTS_SUCCESS = "REPLIES/GET_ACCOUNT_POSTS_SUCCESS";
export const GET_ACCOUNT_POSTS_FAILURE = "REPLIES/GET_ACCOUNT_POSTS_FAILURE";

export const GET_BB_REPLIES_VARIATIONS = "REPLIES/GET_BB_REPLIES_VARIATIONS";
export const GET_BB_REPLIES_VARIATIONS_SUCCESS = "REPLIES/GET_BB_REPLIES_VARIATIONS_SUCCESS";
export const GET_BB_REPLIES_VARIATIONS_FAILURE = "REPLIES/GET_BB_REPLIES_VARIATIONS_FAILURE";

export const SET_ZENDESK_TICKET_ON_REPLY_DATA = "REPLIES/SET_ZENDESK_TICKET_ON_REPLY_DATA";
