import React from "react";
import { useSelector } from "react-redux";

import SavedReplyCard from "../common/itemCard/ItemCard";

import { IReplyPresetList } from "./helper";
import { getSavedRepliesSelector } from "@store/replies/selectors";

import s from "./SavedReplyList.module.less";

export default function PresetList({ onReplyClick }: IReplyPresetList) {
  const savedReplies = useSelector(getSavedRepliesSelector);

  return (
    <div className={s.bbPresetList}>
      {savedReplies?.presets?.map(preset => (
        <SavedReplyCard
          name={preset.name}
          message={preset.message}
          key={preset.id}
          onItemClick={() => {
            onReplyClick?.(preset);
          }}
        />
      ))}
    </div>
  );
}
