import React, { useState } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Chip, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import ColorDot from "@components/_common/ColorDot";
import DropdownMenu, { DropdownMenuItem } from "@components/_common/DropdownMenu";
import DeleteConfirmation from "@components/_common/DeleteConfirmation/DeleteConfirmation";

import s from "./ItemCard.module.less";

import { ICON_MAPPING } from "./helpers";
import { IItemCardProps, IItemAction } from "./types";

import { MoreActionsIcon } from "@assets/index";

const ItemCard: React.FC<IItemCardProps> = ({
  name,
  message,
  isDefault,
  tooltip,
  actions = [],
  tags,
  suffix,
  color,
  onItemClick,
}) => {
  const { t } = useTranslation();

  const [confirmationAction, setConfirmationAction] = useState<IItemAction>();

  const renderActions = () => {
    if (!actions.length) {
      return null;
    }

    const handleSelect = (key: string) => {
      const action = actions.find(a => a.key === key);
      if (action) {
        if (action.confirmationText) {
          setConfirmationAction(action);
        } else {
          action.onActionClick();
        }
      }
    };

    return (
      <div className={s.bbModalItemCardContainerAction}>
        <DropdownMenu
          placement="bottom-end"
          trigger={() => (
            <button className={s.bbModalItemCardContainerActionTrigger}>
              <MoreActionsIcon />
            </button>
          )}
        >
          {closeMenu =>
            actions.map(({ key, label }) => (
              <DropdownMenuItem
                key={key}
                onClick={() => {
                  handleSelect(key);
                  closeMenu();
                }}
              >
                {ICON_MAPPING[key]}
                {t(label)}
              </DropdownMenuItem>
            ))
          }
        </DropdownMenu>
      </div>
    );
  };

  return (
    <div className={s.bbModalItemCard}>
      <div
        className={classNames(s.bbModalItemCardContainer, {
          [s.bbModalItemCardContainerClickable]: onItemClick,
        })}
      >
        <Tooltip title={tooltip} mouseEnterDelay={0.2}>
          <div onClick={onItemClick} className={s.bbModalItemCardContainerBody}>
            <div className={s.bbModalItemCardContainerBodyTitle}>
              {color && <ColorDot color={color} />}
              {name}
              {isDefault && (
                <span className={s.bbModalItemCardContainerBodyDefaultChip}>
                  {t("generic:default")}
                </span>
              )}
            </div>
            {message && <div className={s.bbModalItemCardContainerBodyDescription}>{message}</div>}
            {!!tags?.length && (
              <div className={s.bbModalItemCardTags}>
                {tags.map(({ id, label }) => (
                  <Chip key={id} _size="xs" text={label} _type="white" />
                ))}
              </div>
            )}
          </div>
        </Tooltip>
        <div className={s.bbModalItemCardContentRight}>{suffix}</div>

        {renderActions()}
      </div>
      {confirmationAction?.confirmationText && (
        <DeleteConfirmation
          message={confirmationAction.confirmationText}
          onConfirm={() => confirmationAction?.onActionClick()}
          onCancel={() => setConfirmationAction(undefined)}
        />
      )}
    </div>
  );
};

export default ItemCard;
