import classNames from "classnames/bind";
import React from "react";
import { useTranslation } from "react-i18next";

import { Col } from "antd";
import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./ProfilesTable.module.less";

import { platformIconName } from "@utils/platform";
import { ProfilesTableRowProps } from "./ProfilesTable.types";
import { getPlatformIcon } from "./ProfilesTable.helpers";
import { formatter } from "@components/_common/CardStat/helpers";

const ProfilesTableRow = React.memo(({ data: row, index, columns }: ProfilesTableRowProps) => {
  const { t } = useTranslation();

  return (
    <>
      {columns.length && (
        <div
          data-cy="profiles-table-row"
          className={classNames(s.bbProfilesTableRow, {
            [s.bbProfilesTableRowColored]: index % 2 === 1,
          })}
        >
          <Col span={columns[0].colSpan} data-cy="profiles-column" className={s.bbColPlatform}>
            <div data-cy="profiles-platform" className={s.bbPlatformIconSmall}>
              {row?.platform && getPlatformIcon(platformIconName(row.platform))}
            </div>
            <span>{row?.asset_name}</span>
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[1].colSpan}>
            {row?.followers_count != null ? formatter(row?.followers_count) : ""}
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[2].colSpan}>
            {row?.percentage != null ? `${row.percentage}%` : ""}
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[3].colSpan}>
            {row?.growth ? (
              <PerformanceCardPill percentageValue={Number(row.growth)} />
            ) : (
              <span className={s.bbColumnNotAvailable}>
                {t("components:listen:profiles:table:not_available")}
              </span>
            )}
          </Col>
        </div>
      )}
    </>
  );
});

ProfilesTableRow.displayName = "ProfilesTableRow";

export default ProfilesTableRow;
