import { createReducer, ActionType } from "typesafe-actions";
import { getDashboardProfilesActionSuccess, postDashboardActionSuccess } from "./actions";
import { IDashboardState } from "./types";
import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_PROFILES,
  GET_DASHBOARD_PROFILES_SUCCESS,
  GET_DASHBOARD_PROFILES_FAILURE,
} from "./actionTypes";

const initialState = {
  dashboard: undefined,
  profiles: undefined,
  fetchingDashboard: false,
  fetchedDashboard: false,
  fetchedDashboardFail: false,
};

export const dashboardReducer = createReducer<IDashboardState>(initialState, {
  //GET_DASHBOARD
  [GET_DASHBOARD]: (state: IDashboardState) => ({
    ...state,
    fetchingDashboard: true,
    fetchedDashboard: false,
    fetchedDashboardFail: false,
  }),
  [GET_DASHBOARD_SUCCESS]: (
    state: IDashboardState,
    action: ActionType<typeof postDashboardActionSuccess>,
  ) => ({
    ...state,
    dashboard: { ...state.dashboard, ...action.payload },
    fetchingDashboard: false,
    fetchedDashboard: true,
    fetchedDashboardFail: false,
  }),
  [GET_DASHBOARD_FAILURE]: (state: IDashboardState) => ({
    ...state,
    fetchingDashboard: false,
    fetchedDashboard: false,
    fetchedDashboardFail: true,
  }),
  [GET_DASHBOARD_PROFILES]: (state: IDashboardState) => ({
    ...state,
    fetchingDashboard: true,
    fetchedDashboard: false,
    fetchedDashboardFail: false,
  }),
  [GET_DASHBOARD_PROFILES_SUCCESS]: (
    state: IDashboardState,
    action: ActionType<typeof getDashboardProfilesActionSuccess>,
  ) => ({
    ...state,
    profiles: action.payload,
    fetchingDashboard: false,
    fetchedDashboard: true,
    fetchedDashboardFail: false,
  }),
  [GET_DASHBOARD_PROFILES_FAILURE]: (state: IDashboardState) => ({
    ...state,
    profiles: undefined,
    fetchingDashboard: false,
    fetchedDashboard: false,
    fetchedDashboardFail: true,
  }),
});
