import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Chip,
  ScrollView,
  SimpleDropdown,
  Tooltip,
} from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SuggestedRepliesCarousel.module.less";

import { ISuggestedRepliesCarouselProps } from "./SuggestedRepliesCarousel.types";
import { ISuggestedReply } from "@store/replies/types";

import { MagicSparklesIcon } from "@assets/index";

export const SuggestedRepliesCarousel: React.FC<ISuggestedRepliesCarouselProps> = ({
  onSelectSuggestedReply,
  suggestedReplies,
}) => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);

  const handleOpenDropdown = (open: boolean) => {
    if (open) {
      setDropdownWidth(triggerRef.current?.offsetWidth || 0);
    }

    setIsOpen(open);
  };

  const handleSelectSuggestedReply = (reply: ISuggestedReply) => {
    onSelectSuggestedReply(reply);
    setIsOpen(false);
  };

  const handleNextPage = () => {
    const nextPage = Math.min(suggestedReplies.length, currentPage + 1);
    setCurrentPage(nextPage);
    handleSelectSuggestedReply(suggestedReplies[nextPage - 1]);
  };

  const handlePrevPage = () => {
    const prevPage = Math.max(1, currentPage - 1);
    setCurrentPage(prevPage);
    handleSelectSuggestedReply(suggestedReplies[prevPage - 1]);
  };

  const handleSuggestReplyClick = (reply: ISuggestedReply) => {
    setCurrentPage(suggestedReplies.indexOf(reply) + 1);
    handleSelectSuggestedReply(reply);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        triggerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => document.body.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    onSelectSuggestedReply(suggestedReplies[0]);
  }, [suggestedReplies, onSelectSuggestedReply]);

  if (suggestedReplies.length === 0) {
    return null;
  }

  const trigger = (
    <div className={s.bbSuggestedRepliesCarouselTrigger} ref={triggerRef}>
      <div className={s.bbSuggestedRepliesCarouselTriggerLabel}>
        <MagicSparklesIcon />
        {t("components:reply:suggestedReply")}
      </div>
      <div className={s.bbSuggestedRepliesCarouselTriggerPager}>
        <button
          className={s.bbSuggestedRepliesCarouselTriggerPagerButton}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <ChevronLeftIcon />
        </button>
        <span className={s.bbSuggestedRepliesCarouselTriggerPagerPage}>
          {currentPage}/{suggestedReplies.length}
        </span>
        <button
          className={s.bbSuggestedRepliesCarouselTriggerPagerButton}
          onClick={handleNextPage}
          disabled={currentPage === suggestedReplies.length}
        >
          <ChevronRightIcon />
        </button>
      </div>
      <div>
        <button
          className={classNames(s.bbSuggestedRepliesCarouselAllSuggestions, {
            [s.bbSuggestedRepliesCarouselAllSuggestionsOpen]: isOpen,
          })}
          onClick={() => handleOpenDropdown(!isOpen)}
        >
          {t("components:reply:allSuggestions")} <ChevronDownIcon />
        </button>
      </div>
    </div>
  );

  return (
    <div className={s.bbSuggestedRepliesCarousel}>
      <SimpleDropdown
        open={isOpen}
        setOpen={handleOpenDropdown}
        triggerMode="manual"
        trigger={trigger}
      >
        <ScrollView
          className={s.bbSuggestedRepliesCarouselSuggestions}
          ref={containerRef}
          style={{ width: dropdownWidth || "auto" }}
        >
          <>
            {suggestedReplies.map(reply => (
              <div
                key={reply.message}
                className={s.bbSuggestedRepliesCarouselSuggestionsItem}
                onClick={() => handleSuggestReplyClick(reply)}
              >
                <div className={s.bbSuggestedRepliesCarouselSuggestionsItemText}>
                  {reply.message}
                </div>
                <Tooltip
                  title={t("components:reply:suggestedReplyStatsTooltip", {
                    post: reply.count_post,
                    day: reply.count_asset,
                  })}
                >
                  <>
                    <Chip
                      text={`${reply.count_post}x / ${reply.count_asset}x`}
                      className={s.bbSuggestedRepliesCarouselSuggestionsItemChip}
                      _size="xs"
                      _type="grey"
                    />
                  </>
                </Tooltip>
              </div>
            ))}
          </>
        </ScrollView>
      </SimpleDropdown>
    </div>
  );
};
