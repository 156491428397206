import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError, mergeMap } from "rxjs/operators";

import * as actions from "./actions";
import * as repliesActions from "../replies/actions";

import { RootState } from "..";
import { integrations, ticketReply, tickets } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import {
  IIntegrationsListResponse,
  Integration,
  IntegrationsActions,
  IZendeskTicket,
} from "./types";

const getHeadersFunction = (state$: StateObservable<RootState>) =>
  getHeaders({
    Authorization: state$.value.auth.session.accessToken.jwtToken,
  });

const integrationPlatform = "zendesk";
const integrationsUrl = integrations(integrationPlatform);
const ticketsUrl = tickets(integrationPlatform);

export const getIntegrationsList = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getIntegrationsListAction)),
    switchMap(() =>
      ajax.get<IIntegrationsListResponse>(integrationsUrl, getHeadersFunction(state$)).pipe(
        map(({ response }) => actions.getIntegrationsListSuccessAction(response)),
        catchError(e => handleError(e, actions.getIntegrationsListFailureAction)),
      ),
    ),
  );

export const createIntegration = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createIntegrationAction)),
    mergeMap(({ payload }) =>
      ajax.post(integrationsUrl, payload, getHeadersFunction(state$)).pipe(
        map(() => actions.createIntegrationSuccessAction()),
        catchError(e => handleError(e, actions.createIntegrationFailureAction)),
      ),
    ),
  );

export const updateIntegration = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateIntegrationAction)),
    mergeMap(a => {
      const { id, ...payload } = a.payload;

      return ajax
        .put<Integration>(`${integrationsUrl}/${id}`, payload, getHeadersFunction(state$))
        .pipe(
          map(() => actions.updateIntegrationSuccessAction()),
          catchError(e => handleError(e, actions.updateIntegrationFailureAction)),
        );
    }),
  );

export const deleteIntegration = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.deleteIntegrationAction)),
    switchMap(({ payload: id }) =>
      ajax.delete(`${integrationsUrl}/${id}`, getHeadersFunction(state$)).pipe(
        map(() => actions.deleteIntegrationSuccessAction()),
        catchError(e => handleError(e, actions.deleteIntegrationFailureAction)),
      ),
    ),
  );

export const createTicket = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createTicketAction)),
    mergeMap(({ payload }) =>
      ajax.post<IZendeskTicket>(ticketsUrl, payload, getHeadersFunction(state$)).pipe(
        map(e => e.response),
        switchMap(response => [
          repliesActions.setZendeskTicketOnReplyDataAction(response),
          actions.createTicketSuccessAction(),
        ]),
        catchError(e => handleError(e, actions.createTicketFailureAction)),
      ),
    ),
  );

export const updateTicket = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateTicketAction)),
    mergeMap(({ payload }) =>
      ajax
        .patch(
          tickets(integrationPlatform, payload.id),
          { status: payload.status, priority: payload.priority },
          getHeadersFunction(state$),
        )
        .pipe(
          map(() => actions.updateTicketSuccessAction()),
          catchError(e => handleError(e, actions.updateTicketFailureAction)),
        ),
    ),
  );

export const sendNewInternalComment = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.sendNewInternalCommentAction)),
    mergeMap(({ payload }) =>
      ajax
        .post(
          ticketReply(integrationPlatform, payload.ticketId),
          { message: payload.message, is_public: false },
          getHeadersFunction(state$),
        )
        .pipe(
          map(() => actions.sendNewInternalCommentSuccessAction()),
          catchError(e => handleError(e, actions.sendNewInternalCommentFailureAction)),
        ),
    ),
  );
