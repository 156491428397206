import React from "react";
import classNames from "classnames/bind";

import s from "./Accordion.module.less";

import { ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IAccordionProps {
  title: React.ReactNode;
  collapsed: boolean;
  body: React.ReactNode;
  headerPrefix?: React.ReactNode;
  topPrefix?: React.ReactNode;
  headerSuffix?: React.ReactNode;
  bottomSuffix?: React.ReactNode;
  type?: "grey" | "white";
  setCollapsed: () => void;
  className?: string;
}

const Accordion: React.FC<IAccordionProps> = ({
  title,
  body,
  topPrefix,
  bottomSuffix,
  headerPrefix,
  headerSuffix,
  collapsed,
  setCollapsed,
  type = "grey",
  className,
}) => (
  <div
    className={classNames(
      s.bbAccordion,
      s[`bbAccordion-${type}`],
      { [s.bbAccordionCollapsed]: collapsed },
      className,
    )}
  >
    {topPrefix}
    <div className={s.bbAccordionHeader} onClick={setCollapsed}>
      <div className={s.bbAccordionHeaderLeft}>
        {headerPrefix}
        <span>{title}</span>
      </div>
      <div className={s.bbAccordionHeaderRight}>
        {headerSuffix}
        <ChevronDownIcon />
      </div>
    </div>
    {bottomSuffix}
    <div className={s.bbAccordionBody}>
      <div>{body}</div>
    </div>
  </div>
);

export default Accordion;
