// Integrations
export const GET_INTEGRATIONS_LIST = "INTEGRATIONS/GET_INTEGRATIONS_LIST";
export const GET_INTEGRATIONS_LIST_SUCCESS = "INTEGRATIONS/GET_INTEGRATIONS_LIST_SUCCESS";
export const GET_INTEGRATIONS_LIST_FAILURE = "INTEGRATIONS/GET_INTEGRATIONS_LIST_FAILURE";

export const CREATE_INTEGRATION = "INTEGRATIONS/CREATE_INTEGRATION";
export const CREATE_INTEGRATION_SUCCESS = "INTEGRATIONS/CREATE_INTEGRATION_SUCCESS";
export const CREATE_INTEGRATION_FAILURE = "INTEGRATIONS/CREATE_INTEGRATION_FAILURE";

export const UPDATE_INTEGRATION = "INTEGRATIONS/UPDATE_INTEGRATION";
export const UPDATE_INTEGRATION_SUCCESS = "INTEGRATIONS/UPDATE_INTEGRATION_SUCCESS";
export const UPDATE_INTEGRATION_FAILURE = "INTEGRATIONS/UPDATE_INTEGRATION_FAILURE";

export const DELETE_INTEGRATION = "INTEGRATIONS/DELETE_INTEGRATION";
export const DELETE_INTEGRATION_SUCCESS = "INTEGRATIONS/DELETE_INTEGRATION_SUCCESS";
export const DELETE_INTEGRATION_FAILURE = "INTEGRATIONS/DELETE_INTEGRATION_FAILURE";

// Tickets
export const CREATE_TICKET = "INTEGRATIONS/CREATE_TICKET";
export const CREATE_TICKET_SUCCESS = "INTEGRATIONS/CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILURE = "INTEGRATIONS/CREATE_TICKET_FAILURE";
export const CLEAR_TICKET_STATE = "INTEGRATIONS/CLEAR_TICKET_STATE";

export const UPDATE_TICKET = "INTEGRATIONS/UPDATE_TICKET";
export const UPDATE_TICKET_SUCCESS = "INTEGRATIONS/UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAILURE = "INTEGRATIONS/UPDATE_TICKET_FAILURE";

export const SEND_NEW_INTERNAL_COMMENT = "INTEGRATIONS/SEND_NEW_INTERNAL_COMMENT";
export const SEND_NEW_INTERNAL_COMMENT_SUCCESS = "INTEGRATIONS/SEND_NEW_INTERNAL_COMMENT_SUCCESS";
export const SEND_NEW_INTERNAL_COMMENT_FAILURE = "INTEGRATIONS/SEND_NEW_INTERNAL_COMMENT_FAILURE";
