import { createReducer, ActionType } from "typesafe-actions";
import { IMessage, IRepliesState, IReplyData } from "./types";
import {
  getRepliesSuccessAction,
  getThreadRepliesSuccessAction,
  getRepliesPageSuccessAction,
  postCommentReplySuccessAction,
  patchRepliesParentSentimentAction,
  postSavedRepliesSuccessAction,
  generateAISuggestedReplySuccessAction,
  deleteSavedRepliesSuccessAction,
  deleteBrandReplySuccessAction,
  postReplyBBTagAction,
  deleteReplyBBTagAction,
  setSavedReplyBBTags,
  setSavedReplyCustomTags,
  deleteBrandReplyAction,
  getThreadRepliesAction,
  setDraftReplyAction,
  uploadReplyImageFailureAction,
  uploadReplyImageSuccessAction,
  getAccountPostsSuccessAction,
  getAccountPostsFailureAction,
  clearRepliesCommentListAction,
  getSavedRepliesVariationsSuccessAction,
  setZendeskTicketOnReplyDataAction,
} from "./actions";
import {
  GET_REPLIES,
  CLEAR_REPLIES,
  GET_REPLIES_SUCCESS,
  GET_REPLIES_FAILURE,
  GET_THREAD_REPLIES,
  GET_THREAD_REPLIES_SUCCESS,
  GET_THREAD_REPLIES_FAILURE,
  GET_REPLIES_PAGE_SUCCESS,
  POST_COMMENT_REPLY_SUCCESS,
  POST_COMMENT_REPLY,
  POST_COMMENT_REPLY_FAILURE,
  POST_SAVED_REPLIES,
  POST_SAVED_REPLIES_SUCCESS,
  POST_SAVED_REPLIES_FAILURE,
  GET_REPLIES_AI_SUGGESTION,
  GET_REPLIES_AI_SUGGESTION_SUCCESS,
  GET_REPLIES_AI_SUGGESTION_FAILURE,
  POST_SAVED_REPLY,
  POST_SAVED_REPLY_SUCCESS,
  POST_SAVED_REPLY_FAILURE,
  PATCH_REPLIES_PARENT_SENTIMENT,
  PATCH_REPLIES_ITEM_SENTIMENT,
  PERFORM_REPLIES_ITEMS_ACTION_SUCCESS,
  PERFORM_REPLIES_PARENT_ACTION_SUCCESS,
  PUT_SAVED_REPLY,
  PUT_SAVED_REPLY_SUCCESS,
  PUT_SAVED_REPLY_FAILURE,
  DELETE_SAVED_REPLIES,
  DELETE_SAVED_REPLIES_SUCCESS,
  DELETE_BRAND_REPLY,
  DELETE_BRAND_REPLY_SUCCESS,
  DELETE_BRAND_REPLY_FAILURE,
  CLEAR_SAVED_REPLY_ALL_TAGS,
  POST_REPLY_BB_TAG,
  DELETE_REPLY_BB_TAG,
  SET_SAVED_REPLY_BB_TAGS,
  SET_SAVED_REPLY_CUSTOM_TAGS,
  CLEAR_REPLIES_COMMENT_LIST,
  CLEAR_REPLYING_THREAD_COMMENT,
  SET_DRAFT_REPLY,
  UPLOAD_REPLY_IMAGE,
  UPLOAD_REPLY_IMAGE_SUCCESS,
  UPLOAD_REPLY_IMAGE_FAILURE,
  CLEAR_REPLY_IMAGE,
  POST_COMMENT_REPLY_REGION_IS_NOT_ALLOWED_FAILURE,
  GET_ACCOUNT_POSTS,
  GET_ACCOUNT_POSTS_SUCCESS,
  GET_ACCOUNT_POSTS_FAILURE,
  GET_REPLIES_ATTACHMENTS,
  GET_REPLIES_ATTACHMENTS_SUCCESS,
  GET_REPLIES_ATTACHMENTS_FAILURE,
  GET_BB_REPLIES_VARIATIONS,
  GET_BB_REPLIES_VARIATIONS_SUCCESS,
  GET_BB_REPLIES_VARIATIONS_FAILURE,
  SET_ZENDESK_TICKET_ON_REPLY_DATA,
} from "./actionTypes";
import {
  POST_CUSTOM_TAG_SUCCESS,
  DELETE_CUSTOM_TAG_FROM_REPLY,
  DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS,
  POST_CUSTOM_TAG,
} from "../customTags/actionTypes";
import {
  postCustomTagSuccessAction,
  deleteCustomTagFromReplyAction,
  deleteCustomTagFromCommentSuccessAction,
  postCustomTagAction,
} from "../customTags/actions";
import { postCommentTagSuccessAction, deleteCommentTagSuccessAction } from "../commentTags/actions";
import { POST_COMMENT_TAG_SUCCESS, DELETE_COMMENT_TAG_SUCCESS } from "../commentTags/actionTypes";
import {
  GET_PROMPT_AI_SUGGESTION,
  GET_PROMPT_AI_SUGGESTION_FAILURE,
  GET_PROMPT_AI_SUGGESTION_SUCCESS,
} from "../repliesAIPrompts/actionTypes";

import { handleRepliesParentState, handleRepliesItemState } from "@utils/replies";
import { isPrivateConversation } from "@utils/comments";
import { addToTagsListing, removeFromTagsListing } from "@utils/tagsListingOperations";

import {
  handleSuccessfulCommentActionState,
  handleFailedCommentActionState,
} from "@utils/comments";
import {
  postCommentsAction,
  postCommentsSuccessAction,
  postCommentsFailureAction,
  getInboxRepliesAction,
  performCommentAction,
  updateCommentSentimentSuccessAction,
  translateCommentAction,
  postCommentsSearchAction,
  postCommentsSearchSuccessAction,
  postCommentsSearchFailureAction,
} from "@store/comments/actions";
import {
  POST_COMMENTS,
  POST_COMMENTS_SUCCESS,
  POST_COMMENTS_FAILURE,
  POST_COMMENTS_SEARCH,
  POST_COMMENTS_SEARCH_SUCCESS,
  POST_COMMENTS_SEARCH_FAILURE,
  GET_INBOX_REPLIES,
  PERFORM_COMMENT_ACTION,
  PERFORM_COMMENT_ACTION_SUCCESS,
  PERFORM_COMMENT_ACTION_FAILURE,
  UPDATE_COMMENT_SENTIMENT,
  TRANSLATE_COMMENT,
} from "@store/comments/actionTypes";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";
import { DEFAULT_COMMENT_LIST_KEY } from "@store/comments/types";

const initialState: IRepliesState = {
  cache: {},
  lists: {
    [DEFAULT_COMMENT_LIST_KEY]: {
      comments: [],
      navigation_id: undefined,
      cursor: undefined,
      info: undefined,
      fetchingComments: false,
      fetchedComments: false,
      fetchedCommentsFail: false,
    },
  },
  data: undefined,
  SavedRepliesVariations: [],
  aiSuggestedReply: undefined,
  replyingThreadComment: undefined,
  repliedThreadComment: undefined,

  fetchedReplies: false,
  fetchingReplies: false,
  fetchedRepliesFail: false,

  fetchingThreadReplies: false,
  fetchedThreadReplies: false,
  fetchedThreadRepliesFail: false,

  fetchingRepliesAttachments: false,
  fetchedRepliesAttachments: false,
  fetchedRepliesAttachmentsFail: false,

  fetchAiSuggestedReply: false,
  fetchedAiSuggestedReply: false,
  fetchedAiSuggestedReplyFail: false,

  searchedSavedReplies: false,
  savedReplyCustomTags: [],
  savedReplyBbTags: [],

  fetchingSavedRepliesVariations: false,
  fetchedSavedRepliesVariations: false,
  fetchedSavedRepliesVariationsFail: false,

  deletingBrandReply: false,
  clearReplies: false,
  draftReplies: {},

  postingCommentReply: false,
  postedCommentReply: false,
  postCommentReplyRegionIsNotAllowedFailure: false,

  // COMMENTS
  actionsProcessing: [],

  // UPLOAD REPLY IMAGE
  replyImage: undefined,
  uploadingReplyImage: false,
  uploadedReplyImage: false,
  uploadingReplyImageFailed: false,
  uploadingReplyImageFailedMessage: undefined,

  // ACCOUNT POSTS
  accountPosts: [],
  gettingAccountPosts: false,
  gotAccountPosts: false,
  gettingAccountPostsFailed: true,
  gettingAccountPostsFailedMessage: undefined,
  gettingAccountPostsHasMore: false,
  gettingAccountPostsPage: 1,

  // AUTOCOMPLETE POSTS
  gettingAutocompletePosts: false,
  gotAutocompletePosts: false,
  gettingAutocompletePostsFailed: false,
  gettingAutocompletePostsFailedMessage: undefined,
};

export const repliesReducer = createReducer<IRepliesState>(initialState, {
  [GET_REPLIES]: (state: IRepliesState) => ({
    ...state,
    fetchingReplies: true,
    fetchedReplies: false,
    fetchedRepliesFail: false,
    clearReplies: false,
    postedCommentReply: false,
  }),
  [GET_REPLIES_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof getRepliesSuccessAction>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingReplies: false,
    fetchedReplies: true,
    fetchedRepliesFail: false,
    clearReplies: false,
  }),
  [GET_REPLIES_FAILURE]: (state: IRepliesState) => ({
    ...state,
    fetchingReplies: false,
    fetchedReplies: false,
    fetchedRepliesFail: true,
    clearReplies: false,
  }),

  [GET_REPLIES_ATTACHMENTS]: (state: IRepliesState) => ({
    ...state,
    fetchingRepliesAttachments: true,
    fetchedRepliesAttachments: false,
    fetchedRepliesAttachmentsFail: false,
  }),
  [GET_REPLIES_ATTACHMENTS_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof getRepliesSuccessAction>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingRepliesAttachments: false,
    fetchedRepliesAttachments: true,
    fetchedRepliesAttachmentsFail: false,
  }),
  [GET_REPLIES_ATTACHMENTS_FAILURE]: (state: IRepliesState) => ({
    ...state,
    fetchingRepliesAttachments: false,
    fetchedRepliesAttachments: false,
    fetchedRepliesAttachmentsFail: true,
  }),

  [GET_THREAD_REPLIES]: (
    state: IRepliesState,
    action: ActionType<typeof getThreadRepliesAction>,
  ) => ({
    ...state,
    replyingThreadComment: action.payload.comment,
    fetchingThreadReplies: true,
    fetchedThreadReplies: false,
    fetchedThreadRepliesFail: false,
  }),
  [GET_THREAD_REPLIES_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof getThreadRepliesSuccessAction>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingThreadReplies: false,
    fetchedThreadReplies: true,
    fetchedThreadRepliesFail: false,
  }),
  [GET_THREAD_REPLIES_FAILURE]: (state: IRepliesState) => ({
    ...state,
    replyingThreadComment: undefined,
    fetchingThreadReplies: false,
    fetchedThreadReplies: false,
    fetchedThreadRepliesFail: true,
  }),
  [CLEAR_REPLYING_THREAD_COMMENT]: (state: IRepliesState) => ({
    ...state,
    replyingThreadComment: undefined,
    repliedThreadComment: undefined,
    fetchingThreadReplies: false,
    fetchedThreadReplies: false,
    fetchedThreadRepliesFail: false,
  }),
  [GET_REPLIES_PAGE_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof getRepliesPageSuccessAction>,
  ) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          cursor: action.payload.cursor,
          items: [...state.data.items, ...action.payload.items],
        },
        fetchingReplies: false,
        fetchedReplies: true,
        fetchedRepliesFail: false,
        clearReplies: false,
      };
    }

    return state;
  },
  [POST_COMMENT_REPLY]: (state: IRepliesState) => ({
    ...state,
    postingCommentReply: true,
    postedCommentReply: false,
    postCommentReplyRegionIsNotAllowedFailure: false,
  }),
  [POST_COMMENT_REPLY_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof postCommentReplySuccessAction>,
  ) => {
    if (state.data) {
      const replyData = action.payload.data;
      const itemsCopy = [...state.data.items];
      const isPublicToPrivateReply = !!action.payload.is_private_reply;

      // Reply happened on a DM or a STORY -> add it as a separate message
      if (isPrivateConversation(state.data.post_placement)) {
        itemsCopy.push(replyData);

        return {
          ...state,
          repliedThreadComment: isPublicToPrivateReply ? replyData : undefined,
          replyingThreadComment: undefined,
          postingCommentReply: false,
          postedCommentReply: true,
          data: {
            ...state.data,
            items: itemsCopy,
          },
        };
      }

      // Reply happened on parent comment -> add `brand_reply` to parent
      if (state.data.replying_comment_id === state.data.parent.id) {
        return {
          ...state,
          postingCommentReply: false,
          postedCommentReply: true,
          data: {
            ...state.data,
            parent: {
              ...state.data.parent,
              brand_reply: action.payload.data.brand_reply,
            },
          },
        };
      } else {
        // Reply happened on some child -> add `brand_reply` to that child
        const childIdx = state.data.items.findIndex(
          ({ id }) => id === state.data?.replying_comment_id,
        );
        itemsCopy[childIdx] = {
          ...itemsCopy[childIdx],
          brand_reply: replyData,
        };

        return {
          ...state,
          postingCommentReply: false,
          postedCommentReply: true,
          data: {
            ...state.data,
            items: itemsCopy,
          },
        };
      }
    }

    return {
      ...state,
      postingCommentReply: false,
      postedCommentReply: true,
    };
  },

  [POST_COMMENT_REPLY_FAILURE]: (state: IRepliesState) => ({
    ...state,
    postingCommentReply: false,
    postedCommentReply: false,
    postCommentReplyRegionIsNotAllowedFailure: false,
  }),
  [POST_COMMENT_REPLY_REGION_IS_NOT_ALLOWED_FAILURE]: (state: IRepliesState) => ({
    ...state,
    postCommentReplyRegionIsNotAllowedFailure: true,
  }),
  [POST_SAVED_REPLIES]: (state: IRepliesState) => ({
    ...state,
  }),
  [POST_SAVED_REPLIES_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof postSavedRepliesSuccessAction>,
  ) => {
    const isSearched = !!(
      (action.payload.query && action.payload.query.length) ||
      (action.payload.presetQuery && action.payload.presetQuery.length)
    );

    return {
      ...state,
      searchedSavedReplies: isSearched,
      savedReplies: {
        items: action.payload.data.items,
        presets: action.payload.data.presets,
        internal_items: action.payload.data.internal_items,
      },
    };
  },
  [POST_SAVED_REPLIES_FAILURE]: (state: IRepliesState) => ({
    ...state,
  }),
  [GET_REPLIES_AI_SUGGESTION]: (state: IRepliesState) => ({
    ...state,
    fetchAiSuggestedReply: true,
    fetchedAiSuggestedReply: false,
    fetchedAiSuggestedReplyFail: false,
  }),
  [GET_REPLIES_AI_SUGGESTION_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof generateAISuggestedReplySuccessAction>,
  ) => {
    return {
      ...state,
      fetchAiSuggestedReply: false,
      fetchedAiSuggestedReply: true,
      fetchedAiSuggestedReplyFail: false,
      aiSuggestedReply: action.payload,
    };
  },
  [GET_REPLIES_AI_SUGGESTION_FAILURE]: (state: IRepliesState) => ({
    ...state,
    fetchAiSuggestedReply: false,
    fetchedAiSuggestedReply: false,
    fetchedAiSuggestedReplyFail: true,
  }),

  [GET_PROMPT_AI_SUGGESTION]: (state: IRepliesState) => ({
    ...state,
    fetchAiSuggestedReply: true,
    fetchedAiSuggestedReply: false,
    fetchedAiSuggestedReplyFail: false,
  }),
  [GET_PROMPT_AI_SUGGESTION_SUCCESS]: (state: IRepliesState) => ({
    ...state,
    fetchAiSuggestedReply: false,
    fetchedAiSuggestedReply: true,
    fetchedAiSuggestedReplyFail: false,
  }),
  [GET_PROMPT_AI_SUGGESTION_FAILURE]: (state: IRepliesState) => ({
    ...state,
    fetchAiSuggestedReply: false,
    fetchedAiSuggestedReply: false,
    fetchedAiSuggestedReplyFail: true,
  }),

  [POST_SAVED_REPLY]: (state: IRepliesState) => ({
    ...state,
  }),
  [POST_SAVED_REPLY_SUCCESS]: (state: IRepliesState) => ({
    ...state,
  }),
  [POST_SAVED_REPLY_FAILURE]: (state: IRepliesState) => ({
    ...state,
  }),

  [PUT_SAVED_REPLY]: (state: IRepliesState) => ({
    ...state,
  }),
  [PUT_SAVED_REPLY_SUCCESS]: (state: IRepliesState) => {
    return {
      ...state,
    };
  },
  [PUT_SAVED_REPLY_FAILURE]: (state: IRepliesState) => ({
    ...state,
  }),

  [DELETE_SAVED_REPLIES]: (state: IRepliesState) => ({
    ...state,
  }),
  [DELETE_SAVED_REPLIES_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof deleteSavedRepliesSuccessAction>,
  ) => {
    return {
      ...state,
      savedReplies: {
        ...state?.savedReplies,
        items:
          action.payload.type === "custom"
            ? state?.savedReplies?.items.filter(item => item.id !== action.payload.id) || []
            : state.savedReplies?.items || [],
        internal_items:
          action.payload.type === "internal"
            ? state?.savedReplies?.internal_items?.filter(item => item.id !== action.payload.id) ||
              []
            : state.savedReplies?.internal_items || [],
      },
    };
  },

  [PATCH_REPLIES_PARENT_SENTIMENT]: (
    state: IRepliesState,
    action: ActionType<typeof patchRepliesParentSentimentAction>,
  ) => {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        parent: state.data.parent && {
          ...state.data.parent,
          sentiment: action.payload.sentiment,
        },
      },
    };
  },
  [PATCH_REPLIES_ITEM_SENTIMENT]: (
    state: IRepliesState,
    action: ActionType<typeof patchRepliesParentSentimentAction>,
  ) => {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        items: state.data.items.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              sentiment: action.payload.sentiment,
            };
          }

          return item;
        }, []),
      },
    };
  },
  [PERFORM_REPLIES_ITEMS_ACTION_SUCCESS]: handleRepliesItemState,
  [PERFORM_REPLIES_PARENT_ACTION_SUCCESS]: handleRepliesParentState,
  [DELETE_BRAND_REPLY]: (
    state: IRepliesState,
    action: ActionType<typeof deleteBrandReplyAction>,
  ) => ({
    ...state,
    deletingBrandReply: true,
    deletedReplyId: action.payload.id,
  }),
  [DELETE_BRAND_REPLY_FAILURE]: (state: IRepliesState) => ({
    ...state,
    deletingBrandReply: false,
  }),
  [DELETE_BRAND_REPLY_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof deleteBrandReplySuccessAction>,
  ) => {
    const isParentComment = state.data?.parent.id === action.payload.id;
    const replyStateCopy = { ...state, deletingBrandReply: false };

    if (isParentComment && replyStateCopy?.data) {
      return {
        ...replyStateCopy,
        data: {
          ...replyStateCopy.data,
          parent: {
            ...replyStateCopy.data.parent,
            brand_reply: undefined,
          },
        },
      };
    } else if (replyStateCopy?.data) {
      return {
        ...replyStateCopy,
        data: {
          ...replyStateCopy.data,
          items: replyStateCopy.data.items?.reduce<IMessage[]>((acc, item) => {
            const isDeleted = item.id === action.payload.id;

            if (isDeleted && item.owned) {
              return acc;
            }

            if (isDeleted && !item.owned) {
              acc.push({ ...item, brand_reply: undefined });
            } else {
              acc.push(item);
            }

            return acc;
          }, []),
        },
      };
    }

    return replyStateCopy;
  },
  [CLEAR_REPLIES]: (state: IRepliesState) => ({
    ...state,
    data: undefined,
    fetchingReplies: false,
    fetchedReplies: false,
    deletingBrandReply: false,
    fetchedRepliesFail: false,
    clearReplies: true,
  }),
  [POST_CUSTOM_TAG]: (state: IRepliesState, action: ActionType<typeof postCustomTagAction>) => {
    const { comment_id, label } = action.payload;

    const messageIdx = state.data?.items.findIndex(({ id }) => id === comment_id);

    if (comment_id) {
      const comment = state.cache[comment_id];

      const newTag: ICustomTag = {
        label: label,
        newlyAdded: true,
      };

      if (comment && messageIdx && state.data) {
        return {
          ...state,
          data: {
            ...state.data,
            items: Object.assign([], {
              ...state.data.items,
              [messageIdx]: {
                ...state.data.items[messageIdx],
                custom_tags_by_user: [
                  ...(state.data.items[messageIdx].custom_tags_by_user || []),
                  newTag,
                ],
              },
            }),
          },
        };
      }

      // Case for comments listing in the reply modal
      if (comment) {
        return {
          ...state,
          cache: {
            ...state.cache,
            [comment_id]: {
              ...state.cache[comment_id],
              custom_tags_by_user: [...(state.cache[comment_id].custom_tags_by_user || []), newTag],
            },
          },
        };
      }

      return state;
    }

    return state;
  },
  [POST_CUSTOM_TAG_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof postCustomTagSuccessAction>,
  ) => {
    const messageIdx = state.data?.items.findIndex(
      message => action.payload.commentId === message.id,
    );

    const comment = state.cache[action.payload.commentId];

    // Add custom tag to reply message
    if (messageIdx !== undefined && messageIdx > -1 && state.data) {
      const replyData = state.data;
      const message = replyData.items[messageIdx];
      const newTagIndex = message.custom_tags_by_user?.findIndex(
        tag => tag.label === action.payload.tag.label,
      );
      let message_custom_tags = replyData.message_custom_tags;

      if (replyData.post_placement && isPrivateConversation(replyData.post_placement)) {
        message_custom_tags = addToTagsListing(
          replyData.message_custom_tags || [],
          action.payload.tag,
        );
      }

      if (newTagIndex !== undefined && newTagIndex > -1) {
        return {
          ...state,
          data: {
            ...state.data,
            message_custom_tags: Object.assign([], message_custom_tags),
            items: Object.assign([], {
              ...state.data.items,
              [messageIdx]: {
                ...state.data.items[messageIdx],
                custom_tags_by_user: Object.assign([], {
                  ...(state.data.items[messageIdx].custom_tags_by_user || []),
                  [newTagIndex]: {
                    ...action.payload.tag,
                    newlyAdded: true,
                  },
                }),
              },
            }),
          },
        };
      }
    }

    // Case for comments listing in the reply modal
    if (comment) {
      const newTagIndex = comment.custom_tags_by_user?.findIndex(
        tag => tag.label === action.payload.tag.label,
      );

      if (newTagIndex !== undefined && newTagIndex > -1) {
        return {
          ...state,

          cache: {
            ...state.cache,
            [comment.id]: {
              ...comment,
              custom_tags_by_user: Object.assign([], {
                ...(comment.custom_tags_by_user || []),
                [newTagIndex]: {
                  ...action.payload.tag,
                  newlyAdded: true,
                },
              }),
            },
          },
        };
      }
    }

    // Add custom tag to saved reply
    if (!action.payload.commentId) {
      return {
        ...state,
        savedReplyCustomTags: [...state.savedReplyCustomTags, action.payload.tag],
      };
    }

    return state;
  },

  [DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof deleteCustomTagFromCommentSuccessAction>,
  ) => {
    const message = state.data?.items.find(m => m.id === action.payload.comment_id);
    const comment = state.cache[action.payload.comment_id];
    const removedTagId = action.payload.id;

    // Case for comments listing in the reply modal
    if (comment) {
      return {
        ...state,

        cache: {
          ...state.cache,
          [comment.id]: {
            ...comment,
            custom_tags_by_user: comment.custom_tags_by_user?.filter(
              tag => tag.id !== removedTagId,
            ),
            custom_tags_by_automation: comment.custom_tags_by_automation?.filter(
              tag => tag.id !== removedTagId,
            ),
          },
        },
      };
    }

    if (message && state.data) {
      const replyData = state.data;
      let message_custom_tags = replyData.message_custom_tags;

      if (replyData.post_placement && isPrivateConversation(replyData.post_placement)) {
        message_custom_tags = removeFromTagsListing(
          replyData.message_custom_tags || [],
          action.payload.id,
        );
      }

      return {
        ...state,
        data: {
          ...state.data,
          message_custom_tags: Object.assign([], message_custom_tags),
          items: state.data.items.map<IMessage>(currentComment => {
            const shouldDeleteTags = currentComment.id === message.id;

            if (shouldDeleteTags) {
              const newCustomTagsByUser = currentComment.custom_tags_by_user?.filter(
                tag => tag.id !== removedTagId,
              );
              const newCustomTagsByAutomation = currentComment.custom_tags_by_automation?.filter(
                tag => tag.id !== removedTagId,
              );

              return {
                ...currentComment,
                custom_tags_by_user: newCustomTagsByUser,
                custom_tags_by_automation: newCustomTagsByAutomation,
              };
            }

            return currentComment;
          }),
        },
      };
    }

    return state;
  },

  [POST_COMMENT_TAG_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof postCommentTagSuccessAction>,
  ) => {
    const messageIdx = state.data?.items.findIndex(({ id }) => id === action.payload.commentId);
    const comment = state.cache[action.payload.commentId];

    // Case for comments listing in the reply modal
    if (comment) {
      const tags = comment.tags;
      const tagExists = tags?.some(tag => tag.id === action.payload.tagId);

      if (action.payload.tag && !tagExists) tags?.push(action.payload.tag);

      return {
        ...state,
        cache: {
          ...state.cache,
          [comment.id]: {
            ...comment,
            tags: Object.assign([], {
              ...tags,
            }),
          },
        },
      };
    }

    if (messageIdx !== undefined && messageIdx > -1 && state.data) {
      const replyData = state.data;
      const tags = replyData.items[messageIdx].tags;
      const message = replyData.items[messageIdx];
      const tagExists = tags.some(tag => tag.id === action.payload.tagId);

      if (action.payload.tag && !tagExists) tags.push(action.payload.tag);

      const message_tags =
        replyData.post_placement && isPrivateConversation(replyData.post_placement)
          ? addToTagsListing(replyData.message_tags || [], action.payload.tag)
          : replyData.message_tags;

      return {
        ...state,
        data: {
          ...state.data,
          message_tags: Object.assign([], message_tags),
          items: Object.assign([], {
            ...state.data.items,
            [messageIdx]: {
              ...message,
              tags: Object.assign([], {
                ...tags,
              }),
            },
          }),
        },
      };
    }

    return state;
  },

  [DELETE_COMMENT_TAG_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof deleteCommentTagSuccessAction>,
  ) => {
    const messageIdx = state.data?.items.findIndex(({ id }) => id === action.payload.commentId);
    const comment = state.cache[action.payload.commentId];

    // Case for comments listing in the reply modal
    if (comment) {
      const tags = comment.tags?.filter(tag => tag.id !== action.payload.tagId);

      return {
        ...state,
        cache: {
          ...state.cache,
          [comment.id]: {
            ...comment,
            tags: Object.assign([], {
              ...tags,
            }),
          },
        },
      };
    }

    if (messageIdx !== undefined && messageIdx > -1 && state.data) {
      const replyData = state.data;
      const tags = replyData.items[messageIdx].tags.filter(tag => tag.id !== action.payload.tagId);
      const message = replyData.items[messageIdx];

      const message_tags =
        replyData.post_placement && isPrivateConversation(replyData.post_placement)
          ? removeFromTagsListing(replyData.message_tags || [], action.payload.tagId)
          : replyData.message_tags;

      return {
        ...state,
        data: {
          ...state.data,
          message_tags: Object.assign([], message_tags),
          items: Object.assign([], {
            ...state.data.items,
            [messageIdx]: Object.assign([], {
              ...message,
              tags,
            }),
          }),
        },
      };
    }

    return state;
  },
  [DELETE_CUSTOM_TAG_FROM_REPLY]: (
    state: IRepliesState,
    action: ActionType<typeof deleteCustomTagFromReplyAction>,
  ) => {
    return {
      ...state,
      savedReplyCustomTags: state.savedReplyCustomTags.filter(tag => tag.id !== action.payload),
    };
  },
  [POST_REPLY_BB_TAG]: (state: IRepliesState, action: ActionType<typeof postReplyBBTagAction>) => {
    return {
      ...state,
      savedReplyBbTags: [...state.savedReplyBbTags, action.payload],
    };
  },
  [DELETE_REPLY_BB_TAG]: (
    state: IRepliesState,
    action: ActionType<typeof deleteReplyBBTagAction>,
  ) => {
    return {
      ...state,
      savedReplyBbTags: state.savedReplyBbTags.filter(tag => tag.id !== action.payload),
    };
  },
  [SET_SAVED_REPLY_BB_TAGS]: (
    state: IRepliesState,
    action: ActionType<typeof setSavedReplyBBTags>,
  ) => ({
    ...state,
    savedReplyBbTags: action.payload,
  }),
  [SET_SAVED_REPLY_CUSTOM_TAGS]: (
    state: IRepliesState,
    action: ActionType<typeof setSavedReplyCustomTags>,
  ) => ({
    ...state,
    savedReplyCustomTags: action.payload,
  }),
  [CLEAR_SAVED_REPLY_ALL_TAGS]: (state: IRepliesState) => ({
    ...state,
    savedReplyCustomTags: [],
    savedReplyBbTags: [],
  }),

  // REPLY MODAL COMMENTS
  [POST_COMMENTS]: (state: IRepliesState, action: ActionType<typeof postCommentsAction>) => {
    if (!action.payload.isReplyModal) return state;

    const conversationListId = action.payload.conversation_list_id || DEFAULT_COMMENT_LIST_KEY;

    return {
      ...state,
      lists: {
        ...state.lists,
        [conversationListId]: {
          ...state.lists[conversationListId],
          fetchingComments: true,
          fetchedComments: false,
          fetchedCommentsFail: false,
        },
      },
    };
  },
  [GET_INBOX_REPLIES]: (state: IRepliesState, action: ActionType<typeof getInboxRepliesAction>) => {
    if (!action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: true,
          fetchedComments: false,
          fetchedCommentsFail: false,
        },
      },
    };
  },
  [POST_COMMENTS_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof postCommentsSuccessAction>,
  ) => {
    if (!action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      cache: {
        ...state.cache,
        ...action.payload.comments.reduce(
          (acc, comment) => ({
            ...acc,
            [comment.id]: comment,
          }),
          {},
        ),
      },
      lists: {
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: false,
          fetchedComments: true,
          fetchedCommentsFail: false,
          info: action.payload.info,
          navigation_id: action.payload.navigation_id,
          cursor: action.payload.cursor,
          comments: action.payload.comments.map(comment => comment.id),
        },
      },
    };
  },

  [POST_COMMENTS_FAILURE]: (
    state: IRepliesState,
    action: ActionType<typeof postCommentsFailureAction>,
  ) => {
    if (!action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: false,
          fetchedComments: false,
          fetchedCommentsFail: true,
        },
      },
    };
  },

  [POST_COMMENTS_SEARCH]: (
    state: IRepliesState,
    action: ActionType<typeof postCommentsSearchAction>,
  ) => {
    if (!action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: true,
          fetchedComments: false,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [POST_COMMENTS_SEARCH_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof postCommentsSearchSuccessAction>,
  ) => {
    if (!action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      cache: {
        ...state.cache,
        ...action.payload.comments.reduce(
          (acc, comment) => ({
            ...acc,
            [comment.id]: comment,
          }),
          {},
        ),
      },
      lists: {
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: false,
          fetchedComments: true,
          fetchedCommentsFail: false,
          info: action.payload.info,
          navigation_id: action.payload.navigation_id,
          cursor: action.payload.cursor,
          comments: action.payload.comments.map(comment => comment.id),
        },
      },
    };
  },

  [POST_COMMENTS_SEARCH_FAILURE]: (
    state: IRepliesState,
    action: ActionType<typeof postCommentsSearchFailureAction>,
  ) => {
    if (!action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: false,
          fetchedComments: false,
          fetchedCommentsFail: true,
        },
      },
    };
  },

  [PERFORM_COMMENT_ACTION]: (
    state: IRepliesState,
    action: ActionType<typeof performCommentAction>,
  ) => {
    const comment = state.cache[action.payload.id];

    if (comment) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [comment.id]: {
            ...comment,
            actionsProcessing: [...state.actionsProcessing, action.payload.action],
          },
        },
      };
    }

    return state;
  },

  [PERFORM_COMMENT_ACTION_SUCCESS]: handleSuccessfulCommentActionState,
  [PERFORM_COMMENT_ACTION_FAILURE]: handleFailedCommentActionState,

  [UPDATE_COMMENT_SENTIMENT]: (
    state: IRepliesState,
    action: ActionType<typeof updateCommentSentimentSuccessAction>,
  ) => {
    const comment = state.cache[action.payload.id];

    if (comment) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [comment.id]: {
            ...comment,
            ...action.payload.data,
          },
        },
      };
    }

    return state;
  },

  [TRANSLATE_COMMENT]: (
    state: IRepliesState,
    action: ActionType<typeof translateCommentAction>,
  ) => {
    const comment = state.cache[action.payload.comment_id];

    if (comment) {
      if (!action.payload.isParent) {
        return {
          ...state,
          cache: {
            ...state.cache,
            [comment.id]: {
              ...comment,
              is_translated: action.payload.isParent
                ? comment.is_translated
                : !comment.is_translated,
            },
          },
        };
      }

      if (comment.parent && action.payload.isParent) {
        return {
          ...state,

          cache: {
            ...state.cache,
            [comment.id]: {
              ...comment,
              parent: {
                ...comment.parent,
                is_translated: !comment.parent?.is_translated,
              },
            },
          },
        };
      }
    }

    return state;
  },

  [CLEAR_REPLIES_COMMENT_LIST]: (
    state: IRepliesState,
    action: ActionType<typeof clearRepliesCommentListAction>,
  ) => {
    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          comments: [],
          cursor: undefined,
          navigation_id: undefined,
          info: undefined,
          fetchingComments: false,
          fetchedComments: false,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [SET_DRAFT_REPLY]: (state: IRepliesState, action: ActionType<typeof setDraftReplyAction>) => {
    const nextState = {
      ...state,
      draftReplies: {
        ...state.draftReplies,
        [action.payload.commentId]: action.payload.message,
      },
    };
    return nextState;
  },

  [UPLOAD_REPLY_IMAGE]: (state: IRepliesState) => ({
    ...state,
    uploadingReplyImage: true,
    uploadedReplyImage: false,
    uploadingReplyImageFailed: false,
  }),

  [UPLOAD_REPLY_IMAGE_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof uploadReplyImageSuccessAction>,
  ) => ({
    ...state,
    uploadingReplyImage: false,
    uploadedReplyImage: true,
    uploadingReplyImageFailed: false,
    replyImage: action.payload,
  }),

  [UPLOAD_REPLY_IMAGE_FAILURE]: (
    state: IRepliesState,
    action: ActionType<typeof uploadReplyImageFailureAction>,
  ) => ({
    ...state,
    uploadingReplyImage: false,
    uploadedReplyImage: false,
    uploadingReplyImageFailed: true,
    uploadingReplyImageFailedMessage: action.payload,
  }),
  [CLEAR_REPLY_IMAGE]: (state: IRepliesState) => ({
    ...state,
    replyImage: undefined,
    uploadingReplyImage: false,
    uploadedReplyImage: false,
    uploadingReplyImageFailed: false,
    uploadingReplyImageFailedMessage: undefined,
  }),

  [GET_ACCOUNT_POSTS]: (state: IRepliesState) => ({
    ...state,
    gettingAccountPosts: true,
    gotAccountPosts: false,
    gettingAccountPostsFailed: false,
  }),
  [GET_ACCOUNT_POSTS_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof getAccountPostsSuccessAction>,
  ) => {
    const accountPosts = [...state.accountPosts, ...action.payload.items];
    const currentPage = action.payload.pagination.page;

    return {
      ...state,
      gettingAccountPosts: false,
      gotAccountPosts: true,
      gettingAccountPostsFailed: false,
      accountPosts: currentPage === 1 ? action.payload.items : accountPosts,
      gettingAccountPostsHasMore: accountPosts.length < action.payload.pagination.total,
      gettingAccountPostsPage: currentPage,
    };
  },
  [GET_ACCOUNT_POSTS_FAILURE]: (
    state: IRepliesState,
    action: ActionType<typeof getAccountPostsFailureAction>,
  ) => ({
    ...state,
    gettingAccountPosts: false,
    gotAccountPosts: false,
    gettingAccountPostsFailed: true,
    gettingAccountPostsFailedMessage: action.payload,
  }),

  // BB SAVED REPLIES
  [GET_BB_REPLIES_VARIATIONS]: (state: IRepliesState) => ({
    ...state,
    fetchingSavedRepliesVariations: true,
    fetchedSavedRepliesVariations: false,
    fetchedSavedRepliesVariationsFail: false,
  }),
  [GET_BB_REPLIES_VARIATIONS_SUCCESS]: (
    state: IRepliesState,
    action: ActionType<typeof getSavedRepliesVariationsSuccessAction>,
  ) => ({
    ...state,
    SavedRepliesVariations: action.payload,
    fetchingSavedRepliesVariations: false,
    fetchedSavedRepliesVariations: true,
    fetchedSavedRepliesVariationsFail: false,
  }),
  [GET_BB_REPLIES_VARIATIONS_FAILURE]: (state: IRepliesState) => ({
    ...state,
    fetchingSavedRepliesVariations: false,
    fetchedSavedRepliesVariations: false,
    fetchedSavedRepliesVariationsFail: true,
  }),
  [SET_ZENDESK_TICKET_ON_REPLY_DATA]: (
    state: IRepliesState,
    action: ActionType<typeof setZendeskTicketOnReplyDataAction>,
  ) => {
    const replyData = { ...state.data, zendesk_ticket: action.payload } as IReplyData;

    return {
      ...state,
      data: replyData,
    };
  },
});
