import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { IIntegrationsState } from "./types";

const initialState: IIntegrationsState = {
  integrations: [],

  fetchingIntegrationsList: false,
  fetchedIntegrationsList: false,
  fetchedIntegrationsListFail: false,

  creatingIntegration: false,
  createdIntegration: false,
  creatingIntegrationFailed: false,
  creatingIntegrationFailedMessage: "",

  updatingIntegration: false,
  updatedIntegration: false,
  updatingIntegrationFailed: false,
  updatingIntegrationFailedMessage: "",

  deletingIntegration: false,
  deletedIntegration: false,
  deletingIntegrationFailed: false,
  deletingIntegrationFailedMessage: "",

  creatingTicket: false,
  createdTicket: false,
  creatingTicketFailed: false,
  creatingTicketFailedMessage: "",

  updatingTicket: false,
  updatedTicket: false,
  updatingTicketFailed: false,
  updatingTicketFailedMessage: "",

  sendingNewInternalComment: false,
  sentNewInternalComment: false,
  sendingNewInternalCommentFailed: false,
  sendingNewInternalCommentFailedMessage: "",
};

const clearIntegrationsMutationsState = (draft: IIntegrationsState) => {
  // Integrations
  draft.creatingIntegration = false;
  draft.createdIntegration = false;
  draft.creatingIntegrationFailed = false;

  draft.updatingIntegration = false;
  draft.updatedIntegration = false;
  draft.updatingIntegrationFailed = false;

  draft.deletingIntegration = false;
  draft.deletedIntegration = false;
  draft.deletingIntegrationFailed = false;
};

export const integrationsReducer = createReducer<IIntegrationsState, ActionType<typeof actions>>(
  initialState,
)
  // Integrations
  .handleAction(actions.getIntegrationsListAction, state =>
    produce(state, draft => {
      draft.fetchingIntegrationsList = true;
      draft.fetchedIntegrationsList = false;
      draft.fetchedIntegrationsListFail = false;
    }),
  )
  .handleAction(actions.getIntegrationsListSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.integrations = action.payload.items;
      draft.fetchingIntegrationsList = false;
      draft.fetchedIntegrationsList = true;
      draft.fetchedIntegrationsListFail = false;
    }),
  )
  .handleAction(actions.getIntegrationsListFailureAction, state =>
    produce(state, draft => {
      draft.integrations = [];
      draft.fetchingIntegrationsList = false;
      draft.fetchedIntegrationsList = false;
      draft.fetchedIntegrationsListFail = true;
    }),
  )
  .handleAction(actions.createIntegrationAction, state =>
    produce(state, draft => {
      clearIntegrationsMutationsState(draft);

      draft.creatingIntegration = true;
      draft.createdIntegration = false;
      draft.creatingIntegrationFailed = false;
    }),
  )
  .handleAction(actions.createIntegrationSuccessAction, state =>
    produce(state, draft => {
      draft.creatingIntegration = false;
      draft.createdIntegration = true;
      draft.creatingIntegrationFailed = false;
    }),
  )
  .handleAction(actions.createIntegrationFailureAction, (state, action) =>
    produce(state, draft => {
      draft.creatingIntegration = false;
      draft.createdIntegration = false;
      draft.creatingIntegrationFailed = true;
      draft.creatingIntegrationFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.updateIntegrationAction, state =>
    produce(state, draft => {
      clearIntegrationsMutationsState(draft);

      draft.updatingIntegration = true;
      draft.updatedIntegration = false;
      draft.updatingIntegrationFailed = false;
    }),
  )
  .handleAction(actions.updateIntegrationSuccessAction, state =>
    produce(state, draft => {
      draft.updatingIntegration = false;
      draft.updatedIntegration = true;
      draft.updatingIntegrationFailed = false;
    }),
  )
  .handleAction(actions.updateIntegrationFailureAction, (state, action) =>
    produce(state, draft => {
      draft.updatingIntegration = false;
      draft.updatedIntegration = false;
      draft.updatingIntegrationFailed = true;
      draft.updatingIntegrationFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.deleteIntegrationAction, state =>
    produce(state, draft => {
      clearIntegrationsMutationsState(draft);

      draft.deletingIntegration = true;
      draft.deletedIntegration = false;
      draft.deletingIntegrationFailed = false;
    }),
  )
  .handleAction(actions.deleteIntegrationSuccessAction, state =>
    produce(state, draft => {
      draft.deletingIntegration = false;
      draft.deletedIntegration = true;
      draft.deletingIntegrationFailed = false;
    }),
  )
  .handleAction(actions.deleteIntegrationFailureAction, (state, action) =>
    produce(state, draft => {
      draft.deletingIntegration = false;
      draft.deletedIntegration = false;
      draft.deletingIntegrationFailed = true;
      draft.deletingIntegrationFailedMessage = action.payload;
    }),
  )
  // Tickets
  .handleAction(actions.clearTicketStateAction, state =>
    produce(state, draft => {
      draft.creatingTicket = false;
      draft.createdTicket = false;
      draft.creatingTicketFailed = false;
      draft.creatingTicketFailedMessage = "";

      draft.updatingTicket = false;
      draft.updatedTicket = false;
      draft.updatingTicketFailed = false;
      draft.updatingTicketFailedMessage = "";

      draft.sendingNewInternalComment = false;
      draft.sentNewInternalComment = false;
      draft.sendingNewInternalCommentFailed = false;
      draft.sendingNewInternalCommentFailedMessage = "";
    }),
  )
  .handleAction(actions.createTicketAction, state =>
    produce(state, draft => {
      draft.creatingTicket = true;
      draft.createdTicket = false;
      draft.creatingTicketFailed = false;
    }),
  )
  .handleAction(actions.createTicketSuccessAction, state =>
    produce(state, draft => {
      draft.creatingTicket = false;
      draft.createdTicket = true;
      draft.creatingTicketFailed = false;
    }),
  )
  .handleAction(actions.createTicketFailureAction, (state, action) =>
    produce(state, draft => {
      draft.creatingTicket = false;
      draft.createdTicket = false;
      draft.creatingTicketFailed = true;
      draft.creatingTicketFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.updateTicketAction, state =>
    produce(state, draft => {
      draft.updatingTicket = true;
      draft.updatedTicket = false;
      draft.updatingTicketFailed = false;
    }),
  )
  .handleAction(actions.updateTicketSuccessAction, state =>
    produce(state, draft => {
      draft.updatingTicket = false;
      draft.updatedTicket = true;
      draft.updatingTicketFailed = false;
    }),
  )
  .handleAction(actions.updateTicketFailureAction, (state, action) =>
    produce(state, draft => {
      draft.updatingTicket = false;
      draft.updatedTicket = false;
      draft.updatingTicketFailed = true;
      draft.updatingTicketFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.sendNewInternalCommentAction, state =>
    produce(state, draft => {
      draft.sendingNewInternalComment = true;
      draft.sentNewInternalComment = false;
      draft.sendingNewInternalCommentFailed = false;
    }),
  )
  .handleAction(actions.sendNewInternalCommentSuccessAction, state =>
    produce(state, draft => {
      draft.sendingNewInternalComment = false;
      draft.sentNewInternalComment = true;
      draft.sendingNewInternalCommentFailed = false;
    }),
  )
  .handleAction(actions.sendNewInternalCommentFailureAction, (state, action) =>
    produce(state, draft => {
      draft.sendingNewInternalComment = false;
      draft.sentNewInternalComment = false;
      draft.sendingNewInternalCommentFailed = true;
      draft.sendingNewInternalCommentFailedMessage = action.payload;
    }),
  );
