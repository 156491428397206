import { ISavedReply } from "@store/replies/types";
import { ViewChangeFunc } from "../common/modal/types";

export const SavedReplyMessageMaxLimit = 2150;
export const SavedReplyNameMaxLimit = 128;
export const DEBOUNCE_WAIT_TIME = 500;

export enum SavedReplyTabKeys {
  CustomReplies = "0",
  BBReplies = "1",
  Presets = "2",
}

export interface IReplyPresetList {
  onViewChange: ViewChangeFunc;
  onReplyClick?: (reply: ISavedReply) => void;
}

export interface IReplyList {
  type: string;
  onViewChange: ViewChangeFunc;
  onReplyClick?: (reply: ISavedReply) => void;
}
