import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import { Modal } from "@bbdevcrew/bb_ui_kit_fe";
import SavedReplyListTabs from "./SavedReplyListTabs";
import SavedReplyForm from "./savedReplyForm/SavedReplyForm";

import s from "./SavedReplies.module.less";

import { postSavedRepliesAction, clearSavedReplyAllTagsAction } from "@store/replies/actions";

import { SavedReplyTabKeys } from "./helper";
import { Mode, ViewChangeFunc } from "../common/modal/types";
import { ISavedReply } from "@store/replies/types";

interface ISavedRepliesProps {
  mode?: Mode;
  onCancel?: () => void;
  setReply?: (reply: ISavedReply) => void;
}

const SavedReplies: React.FC<ISavedRepliesProps> = ({ onCancel, setReply, mode = "modal" }) => {
  const dispatch = useDispatch();

  const [view, setView] = useState("list");
  const [editItemId, setEditItemId] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>(SavedReplyTabKeys.CustomReplies);

  useEffect(() => {
    dispatch(postSavedRepliesAction());
  }, [dispatch]);

  const onViewChange: ViewChangeFunc = (_view, tab, editId) => {
    setView(_view);
    setSelectedTab(tab);
    dispatch(clearSavedReplyAllTagsAction());

    if (editId) setEditItemId(editId);
  };

  const onReplyClick = (reply: ISavedReply) => {
    setReply?.(reply);
    onCancel?.();
  };

  const list = (
    <SavedReplyListTabs
      onViewChange={onViewChange}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      onReplyClick={mode === "modal" ? onReplyClick : undefined}
    />
  );

  const savedRepliesModalView = (
    <Modal
      centered
      hideFooter
      width={600}
      open={true}
      hideCloseIcon
      hideHeader={true}
      onCancel={onCancel}
      classes={{
        root: s.bbSavedReplyModal,
        body: s.bbSavedReplyModalBody,
      }}
    >
      {view === "list" && list}
      {view === "create" && <SavedReplyForm tab={selectedTab} onViewChange={onViewChange} />}
      {view === "edit" && (
        <SavedReplyForm tab={selectedTab} editItemId={editItemId} onViewChange={onViewChange} />
      )}
    </Modal>
  );

  const savedRepliesListView = (
    <>
      {list}
      <Modal
        centered
        hideFooter
        width={600}
        hideCloseIcon
        hideHeader={true}
        onCancel={onCancel}
        open={view !== "list"}
        classes={{
          root: s.bbSavedReplyModal,
          body: s.bbSavedReplyModalBody,
        }}
      >
        {view === "create" && <SavedReplyForm tab={selectedTab} onViewChange={onViewChange} />}
        {view === "edit" && (
          <SavedReplyForm tab={selectedTab} editItemId={editItemId} onViewChange={onViewChange} />
        )}
      </Modal>
    </>
  );

  return (
    <>
      {mode === "list" && savedRepliesListView}
      {mode === "modal" && savedRepliesModalView}
    </>
  );
};

export default SavedReplies;
