import { action } from "typesafe-actions";

import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_CSV,
  GET_DASHBOARD_CSV_FAILURE,
  GET_DASHBOARD_PROFILES,
  GET_DASHBOARD_PROFILES_SUCCESS,
  GET_DASHBOARD_PROFILES_FAILURE,
} from "./actionTypes";

import {
  AgentPerformanceReportType,
  IDashboard,
  IDashboardPayload,
  IDashboardProfiles,
} from "./types";
import { IFilters } from "../filters/types";

export const postDashboardAction = (payload: IDashboardPayload) => action(GET_DASHBOARD, payload);
export const postDashboardActionSuccess = (data: IDashboard) => action(GET_DASHBOARD_SUCCESS, data);
export const postDashboardActionFailure = () => action(GET_DASHBOARD_FAILURE);

export const getDashboardCSVAction = (filters: IFilters, report_type: AgentPerformanceReportType) =>
  action(GET_DASHBOARD_CSV, { filters, report_type });

export const getDashboardCSVActionFailure = () => action(GET_DASHBOARD_CSV_FAILURE);

export const getDashboardProfilesAction = (payload: IFilters) =>
  action(GET_DASHBOARD_PROFILES, payload);
export const getDashboardProfilesActionSuccess = (data: IDashboardProfiles) =>
  action(GET_DASHBOARD_PROFILES_SUCCESS, data);
export const getDashboardProfilesActionFailure = () => action(GET_DASHBOARD_PROFILES_FAILURE);
