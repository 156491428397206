import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";

import Breadcrumbs from "../../_common/breadcrumbs";
import SectionDivider from "../overview/OverviewSectionDivider";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "../_common/pieChartWidget/PieChartWidget";
import { ProfilesTable } from "./profilesTable/ProfilesTable";

import {
  getDashboardProfilesSelector,
  getDashboardSelectorPending,
} from "@store/dashboard/selectors";

import { getDashboardProfilesAction } from "@store/dashboard/actions";
import { triggerFilteringWithoutUrlAction } from "@store/filters/actions";

import s from "./Profiles.module.less";

import { useCurrentFilters } from "@utils/useCurrentFilters";
import { normalizePlatformData } from "./Profiles.helpers";

import { UserIcon } from "@assets/index";

export const Profiles: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useCurrentFilters();

  const customFilterData = useMemo(() => {
    return {
      data_range_option: filters?.data_range_option || "last_30_days",
      platform_types: filters?.platform_types?.filter(
        item => item !== "trustpilot" && item !== "google_business",
      ),
      asset_ids: filters?.asset_ids,
      start_time: filters?.start_time,
      end_time: filters?.end_time,
    };
  }, [filters]);

  const loading = useSelector(getDashboardSelectorPending);
  const data = useSelector(getDashboardProfilesSelector);

  const getProfilesData = useCallback(
    () => dispatch(getDashboardProfilesAction(customFilterData)),
    [dispatch, customFilterData],
  );

  const triggerFilterReset = useCallback(
    filterData => dispatch(triggerFilteringWithoutUrlAction(filterData)),
    [dispatch],
  );

  useEffect(() => {
    if (filters) {
      getProfilesData();
      if (!filters.data_range_option) {
        triggerFilterReset({
          ...customFilterData,
        });
      }
    }
    // eslint-disable-next-line
  }, [getProfilesData]);

  const normalizeChartData = normalizePlatformData(data?.platforms || []);
  const chartDataTotal = normalizeChartData.find(item => item.rawName === "total");
  const chartDataValue = normalizeChartData.filter(
    item => item.rawName !== "total" && item.value !== 0,
  );

  const initialTableData = data?.items?.filter(item => !!item.id && item.id !== "total") || [];

  return (
    <div className={s.bbProfiles}>
      <Breadcrumbs
        title={
          <div className={s.bbProfilesTitle}>
            <span className={s.bbProfilesTitleLabel}>{t("components:listen:profiles:title")}</span>
            <span className={s.bbProfilesTitleTag}>{t("generic:beta")}</span>
          </div>
        }
      />
      <div className={s.bbProfilesContent}>
        <SectionDivider
          icon={<UserIcon />}
          title={t("components:listen:profiles:subTitle")}
          description={t("components:listen:profiles:description")}
        />
        <div className={s.bbProfilesWidgets}>
          <Card
            headerTitle={t("components:listen:profiles:chart:title")}
            className={classNames(s.bbProfilesChartWrapper)}
          >
            <div className={s.bbProfilesChart}>
              <PieChartWidget
                loading={loading}
                position="center"
                radiuses={[50, 65]}
                sizes={[130, 130]}
                hasLabel={false}
                defaultValue={chartDataTotal}
                chartData={chartDataValue}
                emptyLabel={t("generic:emptyTable")}
              />
            </div>
          </Card>
          <ProfilesTable
            data={initialTableData}
            loading={loading}
            total={data?.items?.find(item => !!item.id && item.id === "total")}
          />
        </div>
      </div>
    </div>
  );
};
