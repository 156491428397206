import classNames from "classnames";
import { Layout, Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../Appfooter/AppFooter";
import AppSidebar from "../AppSidebar/AppSidebar";
import AppHeaderMobile from "../AppHeader/AppHeaderMobile";
import AppFiltersContainer from "@containers/AppFiltersContainer";
import AIInsightsModal from "../AIInsightsModal";

import { clientDataSelector, meSelector } from "@store/me/selectors";
import { predefinedFiltersSelector } from "@store/savedFilters/selectors";
import { filtersOpenSelector, savedFilterValueSelector } from "@store/filters/selectors";
import { isAIInsightsModalExpanded } from "@store/aiInsights/selectors";

import { saveFilterAction, triggerFilteringWithoutUrlAction } from "@store/filters/actions";
import { toggleOpenAIModalAction } from "@store/aiInsights/actions";

import s from "./AppLayout.module.less";

import { IFilters } from "@store/filters/types";
import { ISavedFilter } from "../AppFilter/AppFilters.type";
import { IFormHandler } from "../AppFilter/Filters.type";
import { IAppLayout } from "./AppLayout.types";
import { debounce } from "@bbdevcrew/bb_ui_kit_fe";
import { useAppFilters } from "@utils/appFilters";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { getDefaultFilterValue } from "@utils/filters";
import { removeItemUrlSearch } from "@utils/removeItemUrlSearch";
import { getCustomFiltersBySection } from "./AppLayout.helpers";
import { FilterContext } from "./FilterContext";
import { getCurrentModuleName } from "@utils/paths";
import { getSubFeatureName } from "@utils/feature";
import { useCurrentFilters } from "@utils/useCurrentFilters";
import { selectItem } from "../AppHeader/AppHeaderCommons";
import { AppSidebarProvider } from "../AppSidebar/helpers";
import { AIInsightsModalStateEnum } from "@store/aiInsights/types";

const { Content } = Layout;

const AppLayout: FC<IAppLayout> = ({
  menu = [],
  extra,
  children,
  basename,
  hasHeader,
  hasFooter,
  avatarUrl,
  selectedKey,
  defaultSelectedKey,
  contentClassName,
  onPreRedirect = () => Promise.resolve(),
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const [searchParams] = useSearchParams();
  const FiltersRef = useRef<IFormHandler>(null);
  const { isBatchModeActive } = useBatchMode();
  const filters = useCurrentFilters();

  const subFeatureName = getSubFeatureName();
  const { updateSearchDataAndUrl } = useAppFilters();
  const { content, beforeContent, afterContent } = children;
  const hasNewSidenav = ["listen", "insights", "care_cps"].includes(getCurrentModuleName());
  const isFooterFixed = window.location.pathname.includes("/settings/asset-management/add-assets");
  const isMobile = !screens.md;
  const isAIInsightsAvailable =
    getCurrentModuleName() === "insights" &&
    (searchParams.get("section") === "listen-overview" ||
      searchParams.get("section") === "listen-sentiment_details" ||
      searchParams.get("section") === "topics");

  const [stickyFilterValue, setStickyFilterValue] = useState<string | null>(null);
  const [isBottomReached, setIsBottomReached] = useState(false);
  const layout = document.getElementById("layout-content");

  const me = useSelector(meSelector);
  const clientData = useSelector(clientDataSelector);
  const isAIModalOpen = useSelector(isAIInsightsModalExpanded);
  const filtersPanelOpen = useSelector(filtersOpenSelector);

  const savedFilterValue = useSelector(savedFilterValueSelector);
  const predefinedFilters = useSelector(predefinedFiltersSelector);

  const triggerFilterClick = useCallback(
    (data: IFilters) => dispatch(triggerFilteringWithoutUrlAction(data)),
    [dispatch],
  );

  const setSavedFilterValue = useCallback(
    (id: string | number | null) => dispatch(saveFilterAction(id)),
    [dispatch],
  );

  const closeAiModal = useCallback(
    () => dispatch(toggleOpenAIModalAction(AIInsightsModalStateEnum.Closed)),
    [dispatch],
  );

  const getDefaultFilters = useCallback(
    () => getDefaultFilterValue(me),
    // eslint-disable-next-line
    [clientData],
  );

  const onLogoClick = () => {
    const id = searchParams.get("id");
    const baseUrl = process.env.BASE_URL;
    const newUrl = `${baseUrl}/insights/?section=listen-overview${id ? `&id=${id}` : ""}`;
    window.location.href = newUrl;
  };

  const props = {
    basename: basename || "",
    selectedKey: selectedKey || "",
    defaultSelectedKey,
    screens,
    menu,
    extra,
    avatarUrl,
    hideSubMenu: hasNewSidenav,
    onPreRedirect,
    onLogoClick,
  };

  const hasSavedFilters = useMemo(
    () =>
      hasNewSidenav &&
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "report-profiles" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags" &&
      getCurrentModuleName() !== "care_cps",
    [hasNewSidenav, searchParams],
  );

  const hasFilters = useMemo(() => {
    return (
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags" &&
      (["insights", "care_cps"].includes(getCurrentModuleName()) ||
        ["dashboard", "inbox", "smart-inbox", "transparency"].includes(subFeatureName))
    );
  }, [searchParams, subFeatureName]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onWindowScroll = useCallback(
    debounce(() => {
      if (layout && layout.scrollHeight - (layout.scrollTop + layout.clientHeight) < 50) {
        return setIsBottomReached(true);
      }

      setIsBottomReached(false);
    }, 50),
    [layout],
  );

  useEffect(() => {
    layout?.addEventListener("scroll", onWindowScroll);

    return () => {
      layout?.removeEventListener("scroll", onWindowScroll);
    };
  }, [onWindowScroll, layout]);

  useEffect(() => {
    const savedFilterSearchParam = searchParams.get("saved_filter");

    if (savedFilterSearchParam && !savedFilterValue) setSavedFilterValue(savedFilterSearchParam);
  }, [searchParams, savedFilterValue, setSavedFilterValue]);

  useEffect(() => {
    if (!isAIInsightsAvailable) closeAiModal();
  }, [isAIInsightsAvailable, closeAiModal]);

  const getCustomFilters = useCallback(() => {
    const section = searchParams.get("section");
    return getCustomFiltersBySection(section, t, me);
  }, [searchParams, t, me]);

  const onFilterInternal = useCallback(
    (data: IFilters, param?: string | ISavedFilter | undefined) => {
      if (searchParams.get("section") === "report-posts" || subFeatureName === "transparency") {
        triggerFilterClick(data);
      }

      updateSearchDataAndUrl(data, param);
    },
    [subFeatureName, searchParams, triggerFilterClick, updateSearchDataAndUrl],
  );

  const onSavedStickyChange = useCallback(
    (filterId?: string | number) => {
      const savedFilters = predefinedFilters.find(({ id }) => id === "savedFilters");
      const stickyFilters = predefinedFilters.find(({ id }) => id === "stickyFilters");

      const savedFilter = savedFilters?.items.find(
        ({ id: savedFilterId }) => savedFilterId === Number(filterId),
      );
      const stickyFilter = stickyFilters?.items.find(
        ({ id: stickyFilterId }) => stickyFilterId === filterId,
      );

      const filter = savedFilter || stickyFilter || null;
      const param = savedFilter ? ({ saved_filter: filterId } as ISavedFilter) : "id";

      onFilterInternal(filter?.request || getDefaultFilters(), param);
    },
    [predefinedFilters, onFilterInternal, getDefaultFilters],
  );

  const onResetFilters = useCallback(() => {
    setSavedFilterValue(null);
    setStickyFilterValue(null);
    onSavedStickyChange("");
    FiltersRef.current?.resetFields();
    FiltersRef.current?.initializeFormValues(getDefaultFilters());
    removeItemUrlSearch("id");
    removeItemUrlSearch("saved_filter");
  }, [setSavedFilterValue, setStickyFilterValue, getDefaultFilters, onSavedStickyChange]);

  const onSavedFilterChange = useCallback(
    (id: string | number | null) => {
      const validId = id === "null" ? null : id;

      if (validId) onSavedStickyChange(validId);

      if (validId === null) onResetFilters();
      else setSavedFilterValue(validId);
    },
    [onSavedStickyChange, onResetFilters, setSavedFilterValue],
  );

  const filterContextValue = {
    clientData,
    filters,
    hasNewSidenav,
    stickyFilterValue,
    isBottomReached,
    onFilterInternal,
    onResetFilters,
    customFilters: getCustomFilters(),
    onSavedStickyChange,
    onSavedFilterChange,
    setStickyFilterValue,
    isBatchModeActive,
  };

  const appHeader = !screens.lg ? <AppHeaderMobile {...props} /> : <AppHeader {...props} />;

  return (
    <FilterContext.Provider value={filterContextValue}>
      <AppSidebarProvider>
        <Layout>
          {(hasHeader || hasHeader === undefined) && appHeader}
          <div
            className={classNames(s.bbAppLayoutContentWrapper, {
              [s.bbAppLayoutContentWrapperWithFooter]: isBottomReached,
            })}
          >
            {hasNewSidenav && (
              <AppSidebar
                hasSavedFilters={hasSavedFilters}
                onSavedFilterChange={onSavedFilterChange}
              />
            )}
            <Content
              id="layout-content"
              className={classNames(s.bbLayoutContent, {
                [s.bbLayoutContentSmall]:
                  selectedKey && selectItem(menu, selectedKey) ? false : true,
                [s.bbLayoutContentFull]: hasFooter === false,
              })}
            >
              <div className={contentClassName}>
                {beforeContent}
                {content}
                {afterContent}
                {hasFilters && isMobile && <AppFiltersContainer ref={FiltersRef} />}
              </div>
            </Content>
            {hasFilters && !isMobile && <AppFiltersContainer ref={FiltersRef} />}
            {isAIModalOpen && !filtersPanelOpen && <div className={s.bbLayoutContentWithAIModal} />}
            {isAIInsightsAvailable && <AIInsightsModal />}
          </div>
          {(hasFooter || hasFooter === undefined) && (
            <AppFooter isBottomReached={isBottomReached} isFixed={isFooterFixed} />
          )}
        </Layout>
      </AppSidebarProvider>
    </FilterContext.Provider>
  );
};

export default AppLayout;
